<app-productstoolbar  addLabel="Add Product" (addEvent)="doNewProduct($event)"></app-productstoolbar>
<p-table #dt [value]="Products" [columns]="ProductCols" [lazy]="true" (onLazyLoad)="doProductSearch($event)" [paginator]="true" 
    [rows]="50" [totalRecords]="TotalProductRecords" selectionMode="single" [(selection)]="SelectedProduct" dataKey="uuid" [responsive]="true" [rowsPerPageOptions]="[10,50,100,250,500]" [filterDelay]="1000">
    <ng-template pTemplate="header">
        <tr>
          <th style="width:40px;">&nbsp;</th>
          <th *ngFor="let col of ProductCols" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th>&nbsp;</th>
        </tr>
        <tr>
			<th>&nbsp;</th>
            <th *ngFor="let col of ProductCols">
                <input pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" [value]="dt.filters[col.field]?.value" >
            </th>
			<th>&nbsp;</th>
        </tr>        
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr  [pSelectableRow]="rowData" (dblclick)="doEditProduct($event, rowData)">
			<td style="width:40px;">{{i+1}}</td>
            <td *ngFor="let col of ProductCols">
                    {{rowData[col.field]}}
            </td>
			<td><button pButton type="button" label="Edit" (click)="doEditProduct($event, rowData)"></button>&nbsp;</td>
        </tr>
    </ng-template>
</p-table>

<p-dialog [(visible)]="showform" [modal]="true" [draggable] = "false" [style]="{width: '40vw'}">
    <p-header>
        Add Product
    </p-header>

          <div class="p-grid">
              <div class="p-col-6"><label class="lbl-right">Supplier:</label></div>
              <div class="p-col-6"><p-dropdown [options]="product_suppliers" [(ngModel)]="supplieruuid" appendTo="body" [style]="{'width': '100%'}"></p-dropdown></div>
              <div class="p-col-6"><label class="lbl-right">Type:</label></div>
              <div class="p-col-6"><p-dropdown [options]="product_types" [(ngModel)]="producttype" appendTo="body" [style]="{'width': '100%'}"></p-dropdown></div>
              <div class="p-col-6"><label class="lbl-right">Code:</label></div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="productcode" class="inp-left"></div>
              <div class="p-col-6"><label class="lbl-right">Name:</label></div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="productname" class="inp-left"></div>
              <div class="p-col-6"><label class="lbl-right">Description:</label></div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="product_description" class="inp-left"></div>
          </div>
  <p-footer>
        <button pButton type="button" label='Save' (click)="doSave()"></button>
        <button pButton type="button" label='Cancel' (click)="showform = false;"></button>
    </p-footer>
</p-dialog>