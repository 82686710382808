<p-menubar>
  <button pButton type="button" label="Back" icon="pi pi-trash" (click)="doBack()"></button>&nbsp;
</p-menubar>
<br>
<div class="p-grid">
  <div class="p-col-8">

    			<p-panel>
    			    <p-header>
    			        Lead
    			    </p-header>

    		    	<div class="p-grid">
    		    		<div class="p-col-6">
		    		    	<div class="p-grid p-nogutter">
       						<div class="p-col-4"><label class="lbl-right">Name:</label></div>
       						<div class="p-col-8"><input type="text" pInputText [(ngModel)]="clientname" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       						<div class="p-col-4"><label class="lbl-right">Address:</label></div>
       						<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address1" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       						<div class="p-col-4">&nbsp;</div>
       						<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address2" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       						<div class="p-col-4">&nbsp;</div>
       						<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address3" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       						<div class="p-col-4">&nbsp;</div>
       						<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address4" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       						<div class="p-col-4">&nbsp;</div>
       						<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address5" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       						<div class="p-col-4"><label class="lbl-right">Post Code:</label></div>
       						<div class="p-col-4"><input type="text" pInputText [(ngModel)]="postcode" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
    	                    <div class="p-col-4"></div>
	       					<div class="p-col-4"><label class="lbl-right">Phone:</label></div>
       						<div class="p-col-8"><input type="text" pInputText [(ngModel)]="phone" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       						<div class="p-col-4"><label class="lbl-right">E-mail:</label></div>
       						<div class="p-col-8"><input type="text" pInputText [(ngModel)]="email" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       						</div>
       					</div>
       					<div class="p-col-6">
		    		    	<div class="p-grid p-nogutter">
	       					<div class="p-col-12"><label>Note:</label></div>
	       					<div class="p-col-12">
       						<textarea [rows]="20" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="dnote" [debounce]="500" (onDebounce)="doSaveNote()"></textarea>
       						</div></div>
       					</div>

        			</div>

    			</p-panel>        	
  </div>
  <div class="p-col-4">
          <p-panel>
              <p-header>
                  Details
              </p-header>

              <div class="p-grid p-nogutter">
                <div class="p-col-4"><label class="lbl-right">Best time to call:</label></div>
                <div class="p-col-8"><input type="text" pInputText [(ngModel)]="besttime" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
                <div class="p-col-4"><label class="lbl-right">Interested In:</label></div>
                <div class="p-col-8"><input type="text" pInputText [(ngModel)]="interested" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
                <div class="p-col-4"><label class="lbl-right">Heard From:</label></div>
                <div class="p-col-8"><input type="text" pInputText [(ngModel)]="heardfrom" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
                <div class="p-col-4"><label class="lbl-right">Promo:</label></div>
                <div class="p-col-8"><input type="text" pInputText [(ngModel)]="promo" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
                <div class="p-col-4"><label class="lbl-right">Where From:</label></div>
                <div class="p-col-8"><input type="text" pInputText [(ngModel)]="wherefrom" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
              </div>

          </p-panel>          
          <br>
          <p-panel>
              <p-header>
                  Options
              </p-header>

              <div class="p-grid p-gutter">
                <div class="p-col-4" style="text-align: center;">
                  <button pButton type="button" label="First Call" (click)="doSaveCalls(1)" class="p-button-success" style="width: 100%;" [disabled]="cbone"></button>
                </div>
                <div class="p-col-4" style="text-align: center;">
                  <button pButton type="button" label="Second Call" (click)="doSaveCalls(2)" class="p-button-success" style="width: 100%;" [disabled]="cb2"></button>
                </div>
                <div class="p-col-4" style="text-align: center;">
                  <button pButton type="button" label="Third Call" (click)="doSaveCalls(3)" class="p-button-success" style="width: 100%;" [disabled]="cb3"></button>
                </div>
                <div class="p-col-12" style="text-align: center;">
                  <button pButton type="button" label="Call Back" icon="pi pi-user-plus" (click)="doAddCallBack()" class="p-button-success" style="width: 50%;"></button>
                </div>

                <div class="p-col-12" style="text-align: center;">
                  <button pButton type="button" label="Approved" icon="pi pi-user-plus" (click)="doAddBooking()" class="p-button-success" style="width: 50%;"></button>
                </div>
                <div class="p-col-12" style="text-align: center;">
                  <button pButton type="button" label="Not Approved" icon="pi pi-trash" (click)="doSaveFlags(4)" class="p-button-danger" style="width: 50%;"></button>
                </div>
                <div class="p-col-12" style="text-align: center;">
                  <button pButton type="button" label="Out of Area" icon="pi pi-trash" (click)="doSaveFlags(5)" class="p-button-warning" style="width: 50%;"></button>
                </div>
              </div>

          </p-panel>   
  </div>
</div>       

<!--- REP BOOKING ENTRY FORM --->
<p-dialog [(visible)]="bookingform" [modal]="true" [closable]="false" [draggable] = "false">
    <p-header>
        Rep Booking
    </p-header>
      <div class="p-grid nogutter">
          <div class="p-col-4">Date:</div>
          <div class="p-col-8"><p-calendar [(ngModel)]="bookingdate" dateFormat="dd/mm/yy" appendTo="body" showIcon="true"></p-calendar></div>
          <div class="p-col-4">Rep:</div>
          <div class="p-col-8"> <p-dropdown [options]="reps" [(ngModel)]="bookrep" appendTo="body" [style]="{'width': '100%'}"></p-dropdown></div>
          <div class="p-col-4">Interested In:</div>
          <div class="p-col-8"><input type="text" pInputText [(ngModel)]="bookinterested" style="width: 100%;"></div>
          <div class="p-col-4"><label>Note:</label></div>
          <div class="p-col-8">
            <textarea [rows]="10" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="bookingnote"></textarea>
          </div>
      </div>
  <p-footer>
        <button pButton type="button" label='Book' (click)="doSaveBooking()"></button>
        <button pButton type="button" label='Cancel' (click)="bookingform = false;"></button>
    </p-footer>
</p-dialog>

<!--- CALLBACK FORM --->
<p-dialog [(visible)]="callbackform" [modal]="true" [closable]="false" [draggable] = "false">
    <p-header>
        Call Back
    </p-header>
      <div class="p-grid nogutter">
          <div class="p-col-4">Date:</div>
          <div class="p-col-8"><p-calendar [(ngModel)]="callbackdate" dateFormat="dd/mm/yy" appendTo="body" showIcon="true"></p-calendar></div>
      </div>
  <p-footer>
        <button pButton type="button" label='Call Back' (click)="doSaveCallBack()"></button>
        <button pButton type="button" label='Cancel' (click)="callbackform = false;"></button>
    </p-footer>
</p-dialog>
