import { Component, OnInit } from '@angular/core';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';

@Component({
  selector: 'app-suppliersmanager',
  templateUrl: './suppliersmanager.component.html',
  styleUrls: ['./suppliersmanager.component.css']
})
export class SuppliersmanagerComponent implements OnInit {

  constructor( private sysmessage: MessageService, private rpc: RpcService ) { }

  // Form Bindings
  showform: boolean = false;
  supplieruuid: String = "";
  suppliername: String = "";
  address1: String = "";
  address2: String = "";
  address3: String = "";
  address4: String = "";
  postcode: String = "";
  phone: String = "";
  email: String = "";
  website: String = "";

  // Table Setup
  SupplierCols: any[] = [];

  // Table Records
  Suppliers: any; // Records
  SelectedSupplier: any; // Selected Record
  TotalSupplierRecords: number; // Number of Records
  SupplierSearchEvent: any; // Search Record Filter



  ngOnInit(): void {

    this.SupplierCols = [
      { field: 'supplier_name', header: 'Name' },
      { field: 'address1', header: 'Address' },
      { field: 'postcode', header: 'Postcode' },
      { field: 'phone', header: 'Phone' },
      { field: 'email', header: 'Email' },

    ]

  }

  doSupplierSearch(event){

    this.SupplierSearchEvent = event;

    this.rpc.doPost('suppliers/list', event).subscribe((data: any)=>{
      this.TotalSupplierRecords = data.result.total;
      this.Suppliers = data.result.records;

    }, (error)=>{
      this.Suppliers = [];
      this.TotalSupplierRecords = 0;
       this.sysmessage.add({severity:'error', summary: 'Supplier List', detail:'List of supplier could not be fetched'});
    });


  }

  doNewClient(txt){
    this.supplieruuid = "";
    this.suppliername = "";
    this.address1 = "";
    this.address2 = "";
    this.address3 = "";
    this.address4 = "";
    this.postcode = "";
    this.phone = "";
    this.email = "";
    this.website = "";
    this.showform = true;
  }

  doEditSupplier(event, rowData){
    this.supplieruuid = rowData['uuid'];
    this.suppliername = rowData['supplier_name'];
    this.address1 = rowData['address1'];
    this.address2 = rowData['address2'];
    this.address3 = rowData['address3'];
    this.address4 = rowData['address4'];
    this.postcode = rowData['postcode'];
    this.phone = rowData['phone'];
    this.email = rowData['email'];
    this.website = rowData['website'];
    this.showform = true;
  }


  doSave(){
    // Collect Information
    let dat = { uuid: this.supplieruuid, supplier_name: this.suppliername, address1: this.address1, address2: this.address2, address3: this.address3,
        address4: this.address4, postcode: this.postcode, phone: this.phone, email: this.email, website: this.website }

    console.log(dat);

    this.rpc.doPost('suppliers/save', dat).subscribe((data: any)=>{

       this.sysmessage.add({severity:'info', summary: 'Supplier Details', detail:' Supplier details have been saved'});

       this.showform = false;

       this.doSupplierSearch(this.SupplierSearchEvent);

    }, (error)=>{
       this.sysmessage.add({severity:'error', summary: 'Supplier Details', detail:' Supplier details have not been saved'});
    });      

  }

}
