import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';
import { User } from '../../_models';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-clientedit',
  templateUrl: './clientedit.component.html',
  styleUrls: ['./clientedit.component.css'],
   providers: [ RpcService ],  
})

export class ClienteditComponent implements OnInit {

  user: User;

  id: number;
  cacheid: string;

  lockpay: boolean = false;

  subroute: any;
  mbitems: MenuItem[];
  
  sbcptxtitems: MenuItem[];

  // timezone fix:
  tzoffset: number = (new Date()).getTimezoneOffset() * -60000;

  // client details
  clientname: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  postcode: string;
  phone: string;
  mobile: string;
  email: string;
  facebook: string;

  // Summary
  summarytotal: number;
  summarybalance: number;
  summarymissed: string;

	// Flags
	flg_received: boolean = false;
	flg_fault: boolean = false;

  // Payment Handler
  paymentdate: Date;
  paymentevery: string;
  paymentamount: number;
  paymentlastdate: Date;
  payment_calc: string;
  paymentgateway: string;

  statuses: any[] = [];
  currentstatus: string;

  // Book Rep Form
  bookingform: boolean = false;
  bookingdate: Date;
  bookrep: string;
  bookinterested: string;
  bookingnote: string;
  reps: any[] = [];
  currentrep: string;

  documentsform: boolean = false;

  // Fittings
  fittingform: boolean = false;
  fittingdate: Date;
  fittingrep: string;
  fittingnote: string;
  fits: any[];

  // Docs
  Docs: any;
  SelectedDoc: any;
  TotalDocRecords: number;
  doccols: any[];
  DocSearchEvent: any;
  DocSearchTable: string;


	loading: boolean = false;

  // Jobs
  Jobs: any;
  SelectedJob: any;
  TotalJobRecords: number;
  jobcols: any[];

  JobSearchEvent: any;
  JobSearchTable: string;

  ppAddJobView: boolean;
  ppAddJobDisabled: boolean;
  ppJobHeader: string;
	ppJobType: string;
    ppJobEdit: boolean;
    ppJobReference: string;
    ppJobTotal: number;
    ppJobBalance: any;
    ppJobPayments: number;
    ppJobDeposit: number;
    ppJobDepositTaken: boolean;
    ppJobRepeat: number;
    ppJobRepeatEvery: string;
    ppJobRepeatDate: Date;
    ppJobProductDelivered: boolean;
    ppJobProductDeliveredDate: Date;
    ppJobDocumentDelivered: boolean;
    ppJobDocumentDeliveredDate: Date;
  repeatable_calc: string;
  // Payments
    ppAddPaymentView: boolean;
    ppAddPaymentLabel: string;
    ppAddPaymentDisabled: boolean;
   msgs: any[] = [];
  months: any[] = [];
  years: any[] = [];
  repeatable: any[] = [];

  paymentrepeatdeposit: boolean;
  paymentrepeatdeposititems: any[];
  // Card Details
  tpname: string = '';
  tpcard: string = '';
  tpcvv: string = '';
  tpmonth: string = '';
  tppay: number;
  tpyear: number = 0;
  // Card Job
  tpjob: number;

  // Other
  baseTab: number = 0;

  // Notes
  noteTab: number = 0;
  noteselectedold: number;
  notescountold: number;
 	noteold: string;
  noteoldChange: boolean;
  noteolduser: string;
  noteolddate: Date;
 
  noteselectedacc: number;
  notescountacc: number;
 	noteacc: string;
  noteaccChange: boolean;
  noteaccuser: string;
  noteaccdate: Date;
  
  noteselecteddel: number;
  notescountdel: number;
 	notedel: string;
  notedelChange: boolean;
  notedeluser: string;
  notedeldate: Date;

  
  noteitems: MenuItem[];
  notes: any;
  noteselected: number;
  notescount: number;
  
  
  	// Popup - Add
  	ppNoteView: boolean;
  	ppNoteDisabled: boolean;
  	ppNoteTitle: string;
  	ppNote: string;
  	// Display Note
  	dNote: string;
    dnoteReadOnly: boolean;
    dnoteChange: boolean;
    dnoteuser: string;
    dnotedate: Date;
    dnotecount: string;

    ppAjustPaymentView: boolean;
    ppAjustPaymenttDisabled: boolean;
    ppAdjustPay: number;
    ppAdjustJob: number;


    // Diary
    diaryadd: boolean = false;
    diaryid: number;
    diaryreason: string;
    diaryschedule: Date;
    diaryexists: boolean;
    diarys: any[];

    // Lookups
    jobtypes: any[];
    jobspay: any[];
    jobsamount: any[];

    // Payment History
    pppayhistory: boolean = false;
    PayHistory: any[] = [];
    TotalPayHistoryRecords: number = 0;
    PayHistoryEvent: any;

    StockUser: boolean = false;

  constructor(private sysmessage: MessageService, private rpc: RpcService, private router: Router, private routeparams: ActivatedRoute, private _location: Location, private authenticationService: AuthenticationService ) { 


	this.sbcptxtitems = [
            {label: 'Day 4', icon: 'pi pi-send', command: () => {
                this.doCPTxt(4);
            }},
            {label: 'Day 10', icon: 'pi pi-send', command: () => {
                this.doCPTxt(10);
            }},
            {label: 'Day 21', icon: 'pi pi-send', command: () => {
                this.doCPTxt(21);
            }},
        ];

    this.diaryschedule = new Date(Date.now());
    this.diaryschedule.setDate( this.diaryschedule.getDate() + 7);


	this.statuses = [];
  this.statuses.push({label: 'All', value: null});
  this.statuses.push({label: 'MISCELLANEOUS', value: 'MISCELLANEOUS'});
  this.statuses.push({label: 'CANCELLED', value: 'CANCELLED'});
  this.statuses.push({label: 'ON HOLD', value: 'ON HOLD'});
  this.statuses.push({label: 'PAYING', value: 'PAYING'});
  this.statuses.push({label: 'CHASING PAYMENT', value: 'CHASING PAYMENT'});
  this.statuses.push({label: 'CHASING CARDS', value: 'CHASING CARDS'});
  this.statuses.push({label: 'PAID IN FULL', value: 'PAID IN FULL'});
  this.statuses.push({label: 'DEBT RECOVERY', value: 'DEBT RECOVERY'});
  
  this.reps = [];
  this.reps.push({label:'  NOT SET  ',value: null});

  this.jobtypes = [];
  this.jobtypes.push({label: 'CARPET', value: 'CARPET'});
  this.jobtypes.push({label: 'BED', value: 'BED'});
  this.jobtypes.push({label: 'LINO', value: 'LINO'});
  this.jobtypes.push({label: 'LAMINATE', value: 'LAMINATE'});
  this.jobtypes.push({label: 'SOFA', value: 'SOFA'});
  this.jobtypes.push({label: 'MATTRESS ONLY', value: 'MATTRESS ONLY'});
  this.jobtypes.push({label: 'BED ONLY', value: 'BED ONLY'});
  this.jobtypes.push({label: 'BEDROOM FURNITURE', value: 'BEDROOM FURNITURE'});
  this.jobtypes.push({label: 'BLINDS', value: 'BLINDS'});
  this.jobtypes.push({label: 'DINING SETS', value: 'DINING SETS'});
  this.jobtypes.push({label: 'LIVING ROOM FURNITURE', value: 'LIVING ROOM FURNITURE'});


  this.paymentrepeatdeposititems = [];
  this.paymentrepeatdeposititems.push({label: 'REPEAT', value: false});
  this.paymentrepeatdeposititems.push({label: 'DEPOSIT', value: true});


  	// Notes
    this.ppAddPaymentView = false;
    this.ppAddPaymentDisabled = false;

    this.ppAddJobView = false;
    this.ppAddJobDisabled = false;


        this.months = [];
        this.months.push({label: '01 - Jan', value: '01'});
        this.months.push({label: '02 - Feb', value: '02'});
        this.months.push({label: '03 - Mar', value: '03'});
        this.months.push({label: '04 - Apr', value: '04'});
        this.months.push({label: '05 - May', value: '05'});
        this.months.push({label: '06 - Jun', value: '06'});
        this.months.push({label: '07 - Jul', value: '07'});
        this.months.push({label: '08 - Aug', value: '08'});
        this.months.push({label: '09 - Sept', value: '09'});
        this.months.push({label: '10 - Oct', value: '10'});
        this.months.push({label: '11 - Nov', value: '11'});
        this.months.push({label: '12 - Dec', value: '12'});

        this.years = [];
        let year = new Date().getFullYear();
        for(let i = 0; i < 10; i++ ){
          this.years.push({label: year + i, value: year + i});
        }

        this.repeatable.push({label:'WEEKLY', value:'WEEKLY'});
        this.repeatable.push({label:'FORTNIGHTLY', value:'FORTNIGHTLY'});
        this.repeatable.push({label:'28 DAYS', value:'28 DAYS'});
        this.repeatable.push({label:'MONTHLY', value:'MONTHLY'});

        this.jobcols = [];
        this.jobcols.push({ field: 'jobtype', header: 'Type' });
        this.jobcols.push({ field: 'reference', header: 'Reference' });
        this.jobcols.push({ field: 'total', header: 'Total' });
        this.jobcols.push({ field: 'repeat_pay', header: 'To Pay Every' });
        this.jobcols.push({ field: 'balance', header: 'Balance' });
        this.jobcols.push({ field: 'documents_delivered', header: 'Documents' });
        this.jobcols.push({ field: 'product_delivered', header: 'Delivered On' });
        
        this.doccols = [];
        this.doccols.push({ field: 'dt_created', header: 'Date / Time' });
        this.doccols.push({ field: 'doctype', header: 'Type' });
        this.doccols.push({ field: 'docname', header: 'Name' });
 
  }

  ngOnInit() {

    this.subroute = this.routeparams.params.subscribe(params => {
       this.id = +params['id']; // (+) converts string 'id' to a number
       this.cacheid = 'cv' + this.id;
       this.JobSearchTable = 'jb' + this.id;
       this.mbitems = [
          {
            label: 'Client ID: ' + this.id,
            styleClass: 'bold-font',
          }
        ];
       // Trigers Loading
       this.doLoad();

    });

      this.user = this.authenticationService.userValue;

    if(['cb','dk','developer', 'jm'].indexOf(this.authenticationService.userValue.user) >= 0){
      this.StockUser = true;
    }

    this.rpc.doGet('lookup/reps').subscribe((data: any)=>{
      this.reps = data.result;;
    }); 

    this.rpc.doGet('lookup/fittings').subscribe((data: any)=>{
      this.fits = data.result;;
    }); 

  }

  doLoadActivejobs(){
      this.loading = true;

      this.rpc.doGet('clients/activejobs/' + this.id).subscribe((data: any)=>{
        this.jobspay = data.result.lookup;
        this.jobsamount = data.result.amount;
        setTimeout(()=>{ this.loading = false; }, 3000);
      });

  }


  doLoadClient(){
      this.loading = true;

      this.rpc.doGet('clients/fetchid/' + this.id).subscribe((data: any)=>{
        this.doSetClient(data.result);
        setTimeout(()=>{ this.loading = false; }, 3000);
      });

  }

  doSetClient(cl){

    this.clientname = cl.clientname;
    this.address1 = cl.address1;
    this.address2 = cl.address2;
    this.address3 = cl.address3;
    this.address4 = cl.address4;
    this.address5 = cl.address5;
    this.postcode = cl.postcode;
    this.phone = cl.phone;
    this.mobile = cl.mobile;
    this.email = cl.email;
    this.facebook = cl.facebook;

  }

  doSaveClient(){
  
    if(this.loading) { return; } // Ignore if still loading

    let data = { clientid: this.id, clientname: this.clientname, address1: this.address1, address2: this.address2, address3: this.address3,
      address4: this.address4, address5: this.address5, postcode: this.postcode, phone: this.phone, mobile: this.mobile, email: this.email, facebook: this.facebook };

    this.rpc.doPost('clients/saveid', data).subscribe((data: any)=>{

      this.notify('info','Client Details','Client details have been updated');

    }, (error)=>{
      this.notify('error','Client Details',error);
    });      

  }

  doLoadSummary(){
      this.loading = true;

      this.rpc.doGet('clients/fetchsummary/' + this.id).subscribe((data: any)=>{
        this.doSetSummary(data.result);
        setTimeout(()=>{ this.loading = false; }, 3000);
      });

  }

  doSetSummary(cl){

    this.summarytotal = cl.total;
    this.summarybalance = cl.balance;
    this.summarymissed = cl.missed_last_payment;
    this.currentstatus = cl.status;
    this.currentrep = cl.rep;

  }

  doLoadPayments(){
      this.loading = true;

      this.rpc.doGet('clients/fetchpayments/' + this.id).subscribe((data: any)=>{
        this.doSetPayments(data.result);
        setTimeout(()=>{ this.loading = false; }, 3000);
      });

  }

  doLoadFlags(){
      this.loading = true;

      this.rpc.doGet('clients/fetchflags/' + this.id).subscribe((data: any)=>{
        this.doSetFlags(data.result);
        setTimeout(()=>{ this.loading = false; }, 3000);
      });

  }

  doSetFlags(cl){
    this.flg_received = (cl.received == "1") ? true : false;
    this.flg_fault = (cl.fault == "1") ? true : false;
  }
  
  doSaveFlags(){
  
  let data = { id: this.id, received: false, fault: false };
  data.received = this.flg_received;
  data.fault = this.flg_fault;

    this.rpc.doPost('clients/saveflags', data).subscribe((data: any)=>{

    console.log('flags updated');

    }, (error)=>{
      this.notify('error','Client Details',error);
    });      

  
  }


  // Notes massive change here. start with loading
  doLoadNotes(){
      this.loading = true;

      let data = { id: this.id, area: 'old' };
      this.rpc.doPost('clients/fetchnotes', data).subscribe((data: any)=>{
        this.notescountold = data.result.notes;
        this.doSelectNoteOld({ first: 0 });
        setTimeout(()=>{ this.loading = false; }, 3000);
      });

      data = { id: this.id, area: 'del' };
      this.rpc.doPost('clients/fetchnotes', data).subscribe((data: any)=>{
        this.notescountdel = data.result.notes;
        this.doSelectNoteDel({ first: 0 });
        setTimeout(()=>{ this.loading = false; }, 3000);
      });

      data = { id: this.id, area: 'acc' };
      this.rpc.doPost('clients/fetchnotes', data).subscribe((data: any)=>{
        this.notescountacc = data.result.notes;
        this.doSelectNoteAcc({ first: 0 });
        setTimeout(()=>{ this.loading = false; }, 3000);
      });

  }

  doSelectNoteOld(event){
    this.noteoldChange = true;
    this.noteselectedold = event.first;
    let data = { id: this.id, first: event.first, area: 'old' };
    if(this.notescountold > 0){
      this.rpc.doPost('clients/fetchnote', data).subscribe((data: any)=>{
        this.noteold = atob(data.result.note);
        this.noteolddate = this.toDate(data.result.dt_created);
        this.noteolduser = data.result.username;
        this.notescountold = data.result.notecount;

      }, (error)=>{
        this.notify('error','Client Details',error);
      });      
    } else {

    }
  }

  doSelectNoteAcc(event){
    this.noteaccChange = true;
    this.noteselectedacc = event.first;
    let data = { id: this.id, first: event.first, area: 'acc' };
    if(this.notescountacc > 0){
      this.rpc.doPost('clients/fetchnote', data).subscribe((data: any)=>{
        this.noteacc = atob(data.result.note);
        this.noteaccdate = this.toDate(data.result.dt_created);
        this.noteaccuser = data.result.username;
        this.notescountacc = data.result.notecount;

      }, (error)=>{
        this.notify('error','Client Details',error);
      });      
    }
  }

  doSelectNoteDel(event){
    this.notedelChange = true;
    this.noteselecteddel = event.first;
    let data = { id: this.id, first: event.first, area: 'del' };
    if(this.notescountdel > 0){
      this.rpc.doPost('clients/fetchnote', data).subscribe((data: any)=>{
        this.notedel = atob(data.result.note);
        this.notedeldate = this.toDate(data.result.dt_created);
        this.notedeluser = data.result.username;
        this.notescountdel = data.result.notecount;

      }, (error)=>{
        this.notify('error','Client Details',error);
      });      
    }
  }


  doSaveNote(location: string){
      this.ppNoteDisabled = true;
      let snote = {}
      if(location == 'acc'){
        this.noteselectedacc = 0;
        this.notescountacc = this.notescountacc + 1;
        this.noteaccdate = new Date(Date.now());
        this.noteaccuser = this.user.username; 
        snote = { clientid: this.id, note: btoa(this.noteacc), area: location };
      }
      if(location == 'del'){
        this.noteselecteddel = 0;
        this.notescountdel = this.notescountdel + 1;
        this.notedeldate = new Date(Date.now());
        this.notedeluser = this.user.username; 
        snote = { clientid: this.id, note: btoa(this.notedel), area: location };
      }
      if(location == 'old'){
        this.noteselectedold = 0;
        this.notescountold = this.notescountold + 1;
        this.noteolddate = new Date(Date.now());
        this.noteolduser = this.user.username; 
        snote = { clientid: this.id, note: btoa(this.noteold), area: location };
      }

     this.rpc.doPost('clients/savenote', snote).subscribe((data: any)=>{
        // Nothing to do
     }, (error)=>{
        this.ppNoteDisabled = false;
     });
  }






  notify(typ, summ, det){
    this.sysmessage.add({severity:typ, summary: summ, detail:det});
  }


  doSaveStatus(){

	if(this.loading) { return; }


      let data = { clientid: this.id, status: this.currentstatus };

      this.rpc.doPost('clients/savestatus', data).subscribe((data: any)=>{

        this.notify('info','Status','Client status been updated');

    }, (error)=>{

      this.notify('error','Status',error);

    });      

  }

  doSaveRep(){

	if(this.loading) { return; }

      let data = { clientid: this.id, rep: this.currentrep };

      this.rpc.doPost('client/saverep', data).subscribe((data: any)=>{

        this.notify('info','Assigned','Client assigned user has been updated');

    }, (error)=>{

      this.notify('error','Assigned',error);

    });      

  }  


  

  doSetPayments(d){

    this.paymentdate = this.toDate(d.date);
    this.paymentevery = d.every;
    this.paymentamount = d.amount;
    this.paymentlastdate = this.toDate(d.last_payment);
    this.paymentgateway = d.gateway;

    this.doPaymentCalc(0, 0);

  }

  doSetDiary(d){

    // this.diarys = d;

    //if(d.hasOwnProperty('reason')){
    //  this.diaryid = d.id;
    //  this.diaryreason = d.reason;
    //  this.diaryschedule = this.toDate(d.scheduled);
    //} else {
    //  this.diaryid = 0;
    //  this.diaryreason = '';
    //  this.diaryschedule = new Date(Date.now());
    //  this.diaryschedule.setDate( this.diaryschedule.getDate() + 7);
    //}
    //this.diaryexists = (this.diaryreason.length > 0);
  }

  doCompleteDiary(){
    let data = { clientid: this.id, diaryid: this.diaryid };

      this.rpc.doPost('diary/complete', data).subscribe((data: any)=>{

        // this.cache.store(this.cacheid, 'clientdiary', data);
        this.doSetDiary(data.result);

        this.notify('success','Diary','Diary Entry Completed');

    }, (error)=>{

      this.notify('error','Diary Entry',error);

    });      

  }

  doDiaryCompleted(did){
    let data = { clientid: this.id, diaryid: did };

      this.rpc.doPost('diary/completed', data).subscribe((data: any)=>{

        // this.cache.store(this.cacheid, 'clientdiary', data);
        this.doSetDiary(data.result);

        this.notify('success','Diary','Diary Entry Completed');

    }, (error)=>{

      this.notify('error','Diary Entry',error);

    });      


  }

  doNewDiary(){
    this.diaryschedule = new Date(Date.now());
    this.diaryschedule.setDate( this.diaryschedule.getDate() + 7);
    this.diaryreason = "";
    this.diaryadd = true;
  }

  doSaveNewDiary(){

    let data = { clientid: this.id, reason: this.diaryreason, scheduled: (this.toSQLDate(this.diaryschedule)) };

    this.rpc.doPost('diary/add', data).subscribe((data: any)=>{

        // this.cache.store(this.cacheid, 'clientdiary', data);
        this.doSetDiary(data.result);

        this.notify('success','Diary','Diary Entry Added');
        this.diaryadd = false;

    },
    error=>{
     this.notify('error','Diary Entry',error);

    });
  }




  doLoad(){

	this.loading = true;

    this.doLoadClient();
    this.doLoadSummary();
    this.doLoadFlags();
    this.doLoadNotes();
    this.doLoadPayments();
    this.doLoadActivejobs();

		setTimeout(()=>{ this.loading = false; }, 4000);
  }


  doJobSearch(event){
    this.JobSearchEvent = event;
    event.filters.clientid = { value: this.id };

    this.rpc.doPost('clients/fetchjobs', event).subscribe((data: any)=>{
      this.TotalJobRecords = data.result.total;
      this.Jobs = data.result.records;

      

      // this.cache.store(this.cacheid,'jobsearchtotal', data.result.total);
      // this.cache.store(this.cacheid,'jobsearchrecords', data.result.records);

    });

  }

  doDocSearch(event){
    this.DocSearchEvent = event;
    event.filters.clientid = { value: this.id };

    this.rpc.doPost('clients/fetchdocs', event).subscribe((data: any)=>{
      this.TotalDocRecords = data.result.total;
      this.Docs = data.result.records;

      

      // this.cache.store(this.cacheid,'jobsearchtotal', data.result.total);
      // this.cache.store(this.cacheid,'jobsearchrecords', data.result.records);

    });

  }




  doAddPayment(){

    // Set To Repeat, as long as repeat amount > 0
    this.paymentrepeatdeposit = (this.paymentamount == 0);
    //this.tpjob = event.id;
    // Reset Card Details
    this.tpname = this.clientname;
    this.tpcard = '';
    this.tpcvv = '';
    this.tpmonth = String("00" + (new Date().getMonth() + 1)).slice(-2);
    this.tpyear = (new Date().getFullYear()) + 2;
    this.tppay = 1.00; // need inital, initail payed, and repeat amount passed)

    //this.tpjob = this.jobspay[0].value;

    this.ppAddPaymentLabel = 'Take Payment';

    this.ppAddPaymentView = true;
  }

  doAdjustPayment(event){
    this.ppAdjustJob = event.id;
    this.ppAdjustPay = 0.00;
    this.ppAjustPaymenttDisabled = false;
    this.ppAjustPaymentView = true;

  }

  doPaymentUnlock(){
    setTimeout(()=>{
      this.lockpay = false;
    }, 10000);
  }

  doTakeAdjustment(){
    let data = { clientid: this.id, jobid: this.ppAdjustJob, amount: this.ppAdjustPay }
    this.notify('info', 'Payment Adjustment', 'Adjusting payments, please wait!');

    this.ppAjustPaymenttDisabled = true;

    this.rpc.doPost('payment/adjust', data).subscribe((data: any)=>{

      this.notescount = this.notes.unshift(data.result.note);
      //this.doSelectNote({ first: 0 });
      // this.cache.store(this.cacheid, 'clientnotes', this.notes);

      // Summary
      this.doSetSummary(data.result.summary);
      // this.cache.store(this.cacheid, 'clientsummary', data.result.summary);

      // Jobs
      this.doJobSearch(this.JobSearchEvent); 

      // Message
      if(data.result.status == 'SUCCESS'){
        this.notify('success', 'Adjust Payment', 'Successfully adjusted!');
        this.ppAjustPaymentView = false;
      } else {
        this.notify('warn', 'Adjust Payment', data.result.message);
        this.ppAjustPaymenttDisabled = false;
      }

    }, (error)=>{
      this.notify('error','Adjust Payment',error);
        this.ppAjustPaymenttDisabled = false;
    });  

  }

  doTakePayment(){ // passes the card details onto the server, and handles the response.

    let data = {};

    this.lockpay = true;

    if(this.paymentrepeatdeposit){
      data = { 
        repeat: 'no', clientid: this.id,
        jobid: this.tpjob, amount: this.tppay,
        cardname: this.tpname, cardnumber: this.tpcard, cardcvv: this.tpcvv, cardmonth: this.tpmonth, cardyear: this.tpyear
       };
    } else {
      data = { repeat: 'yes', clientid: this.id, amount: this.paymentamount,
        cardname: this.tpname, cardnumber: this.tpcard, cardcvv: this.tpcvv, cardmonth: this.tpmonth, cardyear: this.tpyear
       };
    }


    this.notify('info','Take Payment', 'Taking Payment, please wait!');


    this.rpc.doPost('payments/takepayment', data).subscribe((data: any)=>{

      // Summary
      this.doLoadSummary();
      //this.doSetSummary(data.result.summary);
      // this.cache.store(this.cacheid, 'clientsummary', data.result.summary);

      // Payments
      this.doLoadPayments();
      //this.doSetPayments(data.result.payments);
      // this.cache.store(this.cacheid, 'clientpayments', data.result.payments);

      // Diary
      //this.doSetDiary(data.result.fdiary);
      // this.cache.store(this.cacheid, 'clientdiary', data.result.fdiary);

      // Refreshes Jobs
      this.doJobSearch(this.JobSearchEvent); 
      this.doPayHistorySearch(this.PayHistoryEvent);

      // Message
      if(data.result.status == 'SUCCESS'){
        this.notify('success','Take Payment', 'Payment successfully taken!');
        this.ppAddPaymentView = false;
      } else {
        this.notify('warn','Take Payment', data.result.MESSAGE);
      }
      this.doPaymentUnlock();

    }, (error)=>{
      this.notify('error','Take Payment',error);
      this.doPaymentUnlock();
    });  


  }

  doRepeatPayment(){ // Just triggers repeat on the server, and does updates.
    let data = { clientid: this.id}
    this.lockpay = true;

    this.notify('info','Repeat Payment', 'Taking repeat payment, please wait!');
    this.rpc.doPost('payments/repeatpayment', data).subscribe((data: any)=>{

      // Summary
      this.doLoadSummary();
      //this.doSetSummary(data.result.summary);
      // this.cache.store(this.cacheid, 'clientsummary', data.result.summary);

      // Payments
      this.doLoadPayments();
      //this.doSetPayments(data.result.payments);
      // this.cache.store(this.cacheid, 'clientpayments', data.result.payments);

      // Diary
      //this.doSetDiary(data.result.fdiary);
      // this.cache.store(this.cacheid, 'clientdiary', data.result.fdiary);

      // Refreshes Jobs
      this.doJobSearch(this.JobSearchEvent); 
      this.doPayHistorySearch(this.PayHistoryEvent);

      // Message
      if(data.result.status == 'SUCCESS'){
        this.notify('success','Repeat Payment', 'Payment successfully taken!');
      } else {
        this.notify('warn','Repeat Payment', data.result.MESSAGE);
      }

      this.doPaymentUnlock();
    }, (error)=>{
      this.notify('error','Repeat Payment',error);
      this.doPaymentUnlock();
    });  


  }

  doPayHistorySearch(event){
    this.PayHistoryEvent = event;
    event.filters.clientid = { value: this.id };
    this.rpc.doPost('clients/fetchpaymenthistory', event).subscribe((data: any)=>{
      this.TotalPayHistoryRecords = data.result.total;
      this.PayHistory = data.result.records;


    });

  }  

  doAddJob(jobtyp){
    this.ppJobEdit = false;
    this.ppJobHeader = 'Add: ';
    this.ppJobType = jobtyp;
    this.ppJobDepositTaken = false;
    this.ppJobReference = '';
    this.ppJobTotal = 0;
    this.ppJobDeposit = 0;
    this.ppJobPayments = 0;
    this.ppJobDepositTaken = false;
    this.ppJobRepeat = 0;
    this.ppJobRepeatEvery = 'WEEKLY';
    this.ppJobRepeatDate = new Date(Date.now());
    this.ppJobRepeatDate.setDate( this.ppJobRepeatDate.getDate() + 7);
    this.repeatable_calc = '';
    this.ppJobProductDelivered = false;
    this.ppJobProductDeliveredDate = new Date(null);
    this.ppJobDocumentDelivered = false;
    this.ppJobDocumentDeliveredDate = new Date(null);
    this.ppAddJobView = true;
  }

  doJobEdit($event, rowData){
    this.SelectedJob = rowData;
    this.ppJobEdit = true;
    this.ppJobHeader = 'Edit: ';
    this.ppJobType = this.SelectedJob.jobtype;
    this.ppJobDepositTaken = (this.SelectedJob.deposit_taken == "YES" );
    this.ppJobReference = this.SelectedJob.reference;
    this.ppJobTotal = this.SelectedJob.total;
    this.ppJobBalance = this.SelectedJob.balance;
    this.ppJobDeposit = this.SelectedJob.deposit;
    this.ppJobPayments = this.ppJobTotal - this.SelectedJob.balance;
    this.ppJobRepeat = this.SelectedJob.repeat_pay;
    this.ppJobRepeatEvery = this.SelectedJob.repeat_every;
    this.ppJobRepeatDate = this.toDate(this.SelectedJob.repeat_on);
    this.repeatable_calc = '';
    this.doJobRepeatCalc('0','thiswillnotaffectanything');
    this.ppJobProductDelivered = (this.SelectedJob.product_delivered == 2);
    if(this.ppJobProductDelivered){
      this.ppJobProductDeliveredDate = this.toDate(this.SelectedJob.product_delivered_date);
    } else {
      this.ppJobProductDeliveredDate = null;
  }

    this.ppJobDocumentDelivered = (this.SelectedJob.documents_delivered == 2);
    if(this.ppJobDocumentDelivered){
      this.ppJobDocumentDeliveredDate = this.toDate(this.SelectedJob.documents_delivered_date);
    } else {
      this.ppJobDocumentDeliveredDate = null;
  }

    this.ppAddJobView = true;
  }

  doSaveJob(isNew: boolean){

    let data = {}

    if(isNew){

      data = {
        clientid : this.id,
        jobtype: this.ppJobType,
        reference: this.ppJobReference,
        total: this.ppJobTotal,
        deposit: this.ppJobDeposit,
        repeat_pay: this.ppJobRepeat,
        repeat_every: this.ppJobRepeatEvery,
        repeat_on: this.toSQLDate(this.ppJobRepeatDate)
      }


    } else {

      // do Check Here.
      if(this.ppJobProductDelivered){
        if(this.ppJobProductDeliveredDate == null){
          this.notify('error','Date Delivered','Date delivered cant be empty!');
          return;
        }
      }

      if(this.ppJobDocumentDelivered){
        if(this.ppJobDocumentDeliveredDate == null){
          this.notify('error','Date Delivered','Date delivered cant be empty!');
          return;
        }
      }

      data = {
        jobid: this.SelectedJob.id,
        clientid : this.id,
        reference: this.ppJobReference,
        total: this.ppJobTotal,
        deposit: this.ppJobDeposit,
        repeat_pay: this.ppJobRepeat,
        repeat_every: this.ppJobRepeatEvery,
        repeat_on: this.toSQLDate(this.ppJobRepeatDate),
        product_delivered: ((this.ppJobProductDelivered) ? 2 : 1),
        product_delivered_date: ((this.ppJobProductDelivered) ? (this.toSQLDate(this.ppJobProductDeliveredDate)) : null),
        document_delivered: ((this.ppJobDocumentDelivered) ? 2 : 1),
        document_delivered_date: ((this.ppJobDocumentDelivered) ? (this.toSQLDate(this.ppJobDocumentDeliveredDate)) : null),
      }

    }

    this.rpc.doPost('clients/savejob', data).subscribe((data: any)=>{
      this.ppAddJobView = false;
      this.ppAddJobDisabled = false;

      this.doLoadSummary();
      this.doLoadPayments();
      this.doJobSearch(this.JobSearchEvent);
      this.doLoadActivejobs();

      // Trigger Add Payment ( Deposit )
      if(isNew){
        this.doAddPayment();
      }
    }, (error)=>{
      this.ppAddJobDisabled = false;
    });


  }

  doJobRepeatCalc(event, field){
    
    if(field == 'Total'){ this.ppJobTotal = event; }
    if(field == 'Deposit'){ this.ppJobDeposit = event; }
    if(field == 'RepeatAmount'){ this.ppJobRepeat = event; }
    if(field == 'RepeatEvery'){ this.ppJobRepeatEvery = event; }

    if((this.ppJobTotal <= 0) || (this.ppJobDeposit <= 0) || (this.ppJobRepeat <= 0)){
      this.repeatable_calc = '';
      return;
    }

    let pmnts = 0;

    if(this.ppJobEdit){
      pmnts = Math.ceil((this.ppJobTotal - this.ppJobPayments) / this.ppJobRepeat);
      this.ppJobBalance = (((this.ppJobTotal - this.ppJobPayments)/100)*100).toFixed(2);
      this.repeatable_calc = "Takes " + (pmnts);
    } else {
      pmnts = Math.ceil((this.ppJobTotal - this.ppJobDeposit) / this.ppJobRepeat);
      this.repeatable_calc = "Takes " + (pmnts + 1 );
    }

    let dt: Date = this.paymentdate;
    if(this.paymentevery == 'MONTHLY'){
      dt.setMonth(dt.getMonth() + pmnts);
    } else {
      let dys = 7;
      if(this.paymentevery == 'FORTNIGHTLY'){ dys = 14; }
      if(this.paymentevery == '28 DAYS'){ dys = 28; }
      dt.setDate(dt.getDate() + Math.ceil(dys * pmnts));
    }

    this.repeatable_calc = this.repeatable_calc + " payments, and will take untill " + dt.getDate() + '/' + dt.getMonth() + '/' + dt.getFullYear();
  }

  doPaymentCalc(event, field){
    
    let save = false;

    //if(field == 'Total'){ this.ppJobTotal = event; }
    //if(field == 'Deposit'){ this.ppJobDeposit = event; }


    let pmnts = 0;

    if (this.paymentdate !== undefined && this.paymentdate !== null) {

      pmnts = Math.ceil(this.summarybalance / this.paymentamount);
      this.payment_calc = "Takes " + (pmnts);
      let dt: Date = new Date(this.paymentdate.getTime());
      if(dt !== undefined){
        if(this.paymentevery == 'MONTHLY'){
          dt.setMonth(dt.getMonth() + pmnts);
        } else {
          let dys = 7;
          if(this.paymentevery == 'FORTNIGHTLY'){ dys = 14; }
          if(this.paymentevery == '28 DAYS'){ dys = 28; }
          dt.setDate(dt.getDate() + Math.ceil(dys * pmnts));
        }
        this.payment_calc = this.payment_calc + " payments, and will take untill " + dt.toLocaleDateString();
    }}

    if(field == 'date'){ this.paymentdate =  event; save = true; }
    if(field == 'every'){ this.paymentevery = event;  save = true;}


    if(save == true && this.paymentdate !== undefined && this.paymentdate !== null){
      this.doUpdatePayment();
    }
  }

  toDate(dt: string){
    if (dt !== undefined && dt !== null) {
      let t = dt.split(/[- :]/);
      let d = new Date(Date.UTC(parseInt(t[0]), parseInt(t[1])-1, parseInt(t[2]), parseInt(t[3]), parseInt(t[4]), parseInt(t[5])));
      return d;
    }
    return null;
  }

  toSQLDate(dt: Date){ 
    return dt.getFullYear() + '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2) + ' ' + String("00" + dt.getHours()).slice(-2) + ':' + String("00" + dt.getMinutes()).slice(-2) + ':' + String("00" + dt.getSeconds()).slice(-2);
  }


  doUpdatePayment(){

      let data = { clientid: this.id, repeat_every: this.paymentevery, repeat_on: (this.toSQLDate(this.paymentdate)) };

      this.rpc.doPost('clients/savepayments', data).subscribe((data: any)=>{
        // does nothing
      }, (error)=>{
        // does nothing
        // does nothing
      });

  }
  
	doCPTxt(txt){

		let data = { clientid: this.id };
		let url = 'smstxt/cpday' + txt;

		  this.rpc.doPost(url, data).subscribe((data: any)=>{

        	this.noteacc = this.noteacc + "\r\n" + (new Date()).toLocaleDateString() + " " + data.result.sms;

	        this.notify('success','Chasing Payment - SMS',data.result.sms);

   		}, (error)=>{
   		  this.notify('error','Chasing Payment - SMS',error);
});


	}

  doAddDocument(){

  }




  doBack(){
	setTimeout(()=>{
    //this.cache.remove(this.cacheid);
    localStorage.removeItem(this.JobSearchTable);
    this._location.back();
		//this.router.navigate([this.cache.fetch('auth','home','/')]);
	}, 50);
  }

  doAddBooking(){
    // Sets up the form
    this.bookingdate = new Date(Date.now());
    this.bookingdate.setDate( this.bookingdate.getDate() + 7);
    this.bookrep = null;

    this.bookinterested = "";
    this.bookingnote = "";
    this.bookingform = true;
  }

  doSaveBooking(){
    let data = { id: this.id, rep: this.bookrep, interested: this.bookinterested, bookon: this.toSQLDate(this.bookingdate), note: btoa(this.bookingnote) };
    console.log(data);

   this.rpc.doPost('bookings/clientbook', data).subscribe((data: any)=>{

      this.bookingform = false;
      this.notify('success','New Booking',data.result.msg);

    }, (error)=>{
      this.notify('error','Client Error',error);
    });
    
  }

  doAddFitting(){
    // Sets up the form
    this.fittingdate = new Date(Date.now());
    this.fittingdate.setDate( this.fittingdate.getDate() + 7);
    this.fittingrep = null;

    this.fittingnote = "";
    this.fittingform = true;
  }

  doSaveFitting(){
    let data = { id: this.id, rep: this.fittingrep, interested: this.fittingrep, bookon: this.toSQLDate(this.fittingdate), note: btoa(this.fittingnote) };
    console.log(data);

   this.rpc.doPost('fittings/clientbook', data).subscribe((data: any)=>{

      this.fittingform = false;
      this.notify('success','New Fitting',data.result.msg);

    }, (error)=>{
      this.notify('error','Client Error',error);
    });
    
  }

  onBasicUploadAuto($event){
    console.log('Upload Event');
    console.log($event);
  }

  onCustomUpload(event){
    console.log('aftersend');
    event.formData.append('ClientId', this.id);
    console.log(event);
    return event;
  }

}

