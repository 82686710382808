import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-productstoolbar',
  templateUrl: './productstoolbar.component.html',
  styleUrls: ['./productstoolbar.component.css']
})
export class ProductstoolbarComponent implements OnInit {

  @Output() addEvent = new EventEmitter<string>();
  @Input() addLabel: String = "";

  callAddEvent(): void { this.addEvent.next('doAdd'); }

  constructor() { }

  ngOnInit(): void {
  }

}
