<div class="p-grid p-nogutter p-align-center" style="background-color: #50535a;">
  <div class="p-col-6" style="text-align: center;">
  	<img style="width: 150px;" src="assets/logo.png" alt="EASY 2 PAY">
  </div>
  <div class="p-col-3" style="text-align: right; color: white;" *ngIf="user">
  	<h2>Logged in: {{user.username}}</h2>
  </div>
  <div class="p-col-3" style="text-align: right; padding-right: 5px;" *ngIf="user">
  	<button pButton type="button" label="Log Out" icon="pi pi-power-off" (click)="doLogout()" ></button>&nbsp;
  </div>
</div>
<p-toast [style]="{marginTop: '10px'}"></p-toast>
<router-outlet></router-outlet>