import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  TransactionDate: Date = new Date();
  AuthorizationCode: String = "";

  cols: any[];
  Clients: any;
  SelectedClient: any;
  TotalClientRecords: number;


  constructor(private sysmsg: MessageService, private rpc: RpcService, private router: Router, private routeparams: ActivatedRoute,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    this.cols = [
      { field: 'ref', header: 'ID' },
      { field: 'clientname', header: 'Name' },
      { field: 'address', header: 'Address' },
      { field: 'postcode', header: 'Post Code' },
      { field: 'phone', header: 'Phone' },
    ];


  }

  toSQLDate(dt: Date){ 
    return dt.getFullYear() + '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2) + ' ' + String("00" + dt.getHours()).slice(-2) + ':' + String("00" + dt.getMinutes()).slice(-2) + ':' + String("00" + dt.getSeconds()).slice(-2);
  }

  doLoad(){

    if(this.AuthorizationCode.length < 4 ){
      this.sysmsg.add({severity:'warn', summary: 'Authorization Code', detail:'Please enter valid authorization code'});
    } else {

    let dat = { TransactionDate: this.toSQLDate(this.TransactionDate), AuthorizationCode: this.AuthorizationCode }

    this.rpc.doPost('transactions/search', dat).subscribe((data: any)=>{

      this.TotalClientRecords = data.result.total;
      this.Clients = data.result.records;
    });

    }

  }

  onRowDblClick($event, rowData){
    let r = "";
    if(rowData.type=='Client'){
      r = '/client/' + rowData.ref;
    } else {
      r = '/lead/' + rowData.ref;

    }
    setTimeout(()=>{
      this.router.navigate([r]);
    }, 50);
  }


}
