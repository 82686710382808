import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        // Check if API url

        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.authenticationService.userValue;
        const isApiUrl = request.url.startsWith(environment.apiUrl);

        if(isApiUrl){

            // as api, set content header as application/json
            let newHeaders = request.headers;
            newHeaders = newHeaders.set('Content-Type', 'application/json');
            
            console.log(user);

            if(user !== null){
                // logged in so add auth token
                newHeaders =  newHeaders.set('auth-token', user.jwttoken);
            }

            request = request.clone({headers: newHeaders});
        }

        return next.handle(request);
    }
}