<br/>
<div class="p-grid">
    <div class="p-col-4 p-offset-4">
    	<p-panel >
    		<p-header>
        		Login
    		</p-header>
			<div class="p-grid">
				<div class="p-col-12 p-md-12">
					<div class="p-inputgroup">
    					<span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
    					<input type="text" pInputText placeholder="Username" [(ngModel)]="user" [disabled]="loading" id="user" name="user">         
					</div>
				</div>
			</div>
			<div class="p-grid">
				<div class="p-col-12 p-md-12">
					<div class="p-inputgroup">
    					<span class="p-inputgroup-addon"><i class="pi pi-unlock"></i></span>
    					<input type="password" pInputText placeholder="Password" [(ngModel)]="pass" [disabled]="loading"  id="pass" name="pass">         
					</div>
				</div>
			</div>
    		<p-footer>
        		<button pButton type="button" label='Login' [disabled]="loading" (click)="login()"></button>
    		</p-footer>
		</p-panel>    	
    </div>
</div>

