import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Webcam
import { WebcamModule } from 'ngx-webcam';

// signature
// import { SignaturePadModule } from '@almothafar/angular-signature-pad';

// Auth
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AuthenticationService } from './_services';

// Directives
import { DebounceDirective } from './_directives/debounce.directive';

//PrimeNG
import {ToastModule} from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TableModule} from 'primeng/table';
import {DataViewModule} from 'primeng/dataview';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {PaginatorModule} from 'primeng/paginator';
import {CalendarModule} from 'primeng/calendar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {MenubarModule} from 'primeng/menubar';
import {TabViewModule} from 'primeng/tabview';
import {TabMenuModule} from 'primeng/tabmenu';
import {MenuItem} from 'primeng/api';
import {DividerModule} from 'primeng/divider';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {FileUploadModule} from 'primeng/fileupload';
import {AutoCompleteModule} from 'primeng/autocomplete';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ClientsearchComponent } from './search/clientsearch/clientsearch.component';
import { ClienteditComponent } from './edit/clientedit/clientedit.component';
import { NewleadsearchComponent } from './search/newleadsearch/newleadsearch.component';
import { NewleadseditComponent } from './edit/newleadsedit/newleadsedit.component';
import { ReportsummaryComponent } from './reports/reportsummary/reportsummary.component';
import { RsdetailComponent } from './reports/rsdetail/rsdetail.component';
import { BookinglistComponent } from './bookings/bookinglist/bookinglist.component';
import { RepsearchComponent } from './search/repsearch/repsearch.component';
import { PhonesearchComponent } from './search/phonesearch/phonesearch.component';
import { RepviewComponent } from './reps/repview/repview.component';
import { RepcontractComponent } from './reps/repcontract/repcontract.component';
import { FittingslistComponent } from './bookings/fittingslist/fittingslist.component';
import { ProductsmanagerComponent } from './products/productsmanager/productsmanager.component';
import { SuppliersmanagerComponent } from './products/suppliersmanager/suppliersmanager.component';
import { StocksmanagerComponent } from './products/stocksmanager/stocksmanager.component';
import { ProductstoolbarComponent } from './toolbar/productstoolbar/productstoolbar.component';
import { TransactionsComponent } from './search/transactions/transactions.component';
import { SearchtoolbarComponent } from './toolbar/searchtoolbar/searchtoolbar.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DebounceDirective,
    ClientsearchComponent,
    ClienteditComponent,
    NewleadsearchComponent,
    NewleadseditComponent,
    ReportsummaryComponent,
    RsdetailComponent,
    BookinglistComponent,
    RepsearchComponent,
    PhonesearchComponent,
    RepviewComponent,
    RepcontractComponent,
    FittingslistComponent,
    ProductsmanagerComponent,
    SuppliersmanagerComponent,
    StocksmanagerComponent,
    ProductstoolbarComponent,
    TransactionsComponent,
    SearchtoolbarComponent
  ],
  imports: [

    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NoopAnimationsModule,

    ToastModule,
    PanelModule,
    InputTextModule,
    ButtonModule,
    ToolbarModule,
    MenubarModule,
    TableModule,
    DropdownModule,
    PaginatorModule,
    DialogModule,
    CalendarModule,
    CheckboxModule,
    OverlayPanelModule,
    FullCalendarModule,
    SplitButtonModule,
    TabViewModule,
    TabMenuModule,
    DataViewModule,
    DividerModule,
    ToggleButtonModule,
    FileUploadModule,
    AutoCompleteModule,

    WebcamModule,

  ],
	providers: [
		{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
	],
  bootstrap: [AppComponent]
})
export class AppModule { }
