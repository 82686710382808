<p-menubar>
        <button pButton type="button" label="Reset Search" icon="pi pi-undo" (click)="doReset();"></button>&nbsp;
        <button pButton type="button" label="Add Client" icon="pi pi-plus" (click)="doAddClient()"></button>&nbsp;
        <button pButton type="button" label="New Leads" (click)="doLeads()"></button>&nbsp;
        <button pButton type="button" label="Bookings" (click)="doBookings()"></button>&nbsp;
        <button pButton type="button" label="Fittings" [routerLink]="['/fittings/list']"></button>&nbsp;
        <button *ngIf="TransactionUser" pButton type="button" label="Transaction Search" [routerLink]="['/transactions']"></button>&nbsp;
        <button *ngIf="StockUser" pButton type="button" label="Stock" [routerLink]="['/products/stock']"></button>&nbsp;
        <button *ngIf="RepView" pButton type="button" label="Reports" icon="fa-plus" (click)="doReports()"></button>
</p-menubar>
<p-table #dt [value]="Clients" [columns]="cols" [lazy]="true" (onLazyLoad)="doClientSearch($event)" [paginator]="true" 
    [rows]="50" [totalRecords]="TotalClientRecords" [loading]="loading" selectionMode="single" [(selection)]="SelectedClient" dataKey="clientid" [responsive]="true" [rowsPerPageOptions]="[10,50,100,250,500]"     stateStorage="local" stateKey="client-search-table" [filterDelay]="1000">
    <ng-template pTemplate="header">
        <tr>
          <th style="width:40px;">&nbsp;</th>
          <th *ngFor="let col of cols" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th style="width:20px;">&nbsp;</th>
        </tr>
        <tr>
			       <th>&nbsp;</th>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
                <input *ngSwitchCase="'clientid'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" [value]="dt.filters[col.field]?.value" >
               <input *ngSwitchCase="'clientname'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value" >
               <input *ngSwitchCase="'phone'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
               <input *ngSwitchCase="'address'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
               <input *ngSwitchCase="'postcode'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
               <p-dropdown *ngSwitchCase="'status'" [options]="statuses" [style]="{'width':'100%'}" (onChange)="dt.filter($event.value, col.field, 'equals')" appendTo="body" [(ngModel)]="currentstatus"></p-dropdown>
               <p-dropdown *ngSwitchCase="'missed_last_payment'" [options]="yesno" [style]="{'width':'100%'}" (onChange)="dt.filter($event.value, col.field, 'equals')" appendTo="body" [(ngModel)]="currentyesno"></p-dropdown>
            </th>
			<th style="width:20px;">&nbsp;</th>
        </tr>        
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr  [pSelectableRow]="rowData" (dblclick)="onRowDblClick($event, rowData)">
			<td style="width:40px;">{{i+1}}</td>
            <td *ngFor="let col of cols">
                    {{rowData[col.field]}}
            </td>
			<td style="width:20px;" [style.background-color]="doGetTraffic(rowData['flg_traffic'])"></td>
        </tr>
    </ng-template>
</p-table>

<p-dialog [(visible)]="ppAddClientView" [modal]="true" [closable]="!ppAddClientDisabled" [draggable] = "false" [style]="{width: '40vw'}">
    <p-header>
        Add Client
    </p-header>

          <div class="p-grid">
              <div class="p-col-6"><label class="lbl-right">Name:</label></div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="clientname" class="inp-left"></div>
              <div class="p-col-6"><label class="lbl-right">Address:</label></div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="address1" class="inp-left"></div>
              <div class="p-col-6">&nbsp;</div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="address2" class="inp-left"></div>
              <div class="p-col-6">&nbsp;</div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="address3" class="inp-left"></div>
              <div class="p-col-6">&nbsp;</div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="address4" class="inp-left"></div>
              <div class="p-col-6">&nbsp;</div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="address5" class="inp-left"></div>
              <div class="p-col-6"><label class="lbl-right">Post Code:</label></div>
              <div class="p-col-3"><input type="text" pInputText [(ngModel)]="postcode" class="inp-left"></div>
              <div class="p-col-3">&nbsp;</div>
              <div class="p-col-6"><label class="lbl-right">Phone:</label></div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="phone" class="inp-left"></div>
              <div class="p-col-6"><label class="lbl-right">Mobile:</label></div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="mobile" class="inp-left"></div>
              <div class="p-col-6"><label class="lbl-right">E-mail:</label></div>
              <div class="p-col-6"><input type="text" pInputText [(ngModel)]="email" class="inp-left"></div>
          </div>
  <p-footer>
        <button pButton type="button" label='Save' [disabled]="ppAddClientDisabled" (click)="doSaveClient()"></button>
        <button pButton type="button" label='Cancel' [disabled]="ppAddClientDisabled" (click)="ppAddClientView = false;"></button>
    </p-footer>
</p-dialog>
