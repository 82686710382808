<p-menubar>
    <button pButton type="button" label="Reset Search" icon="pi pi-undo" (click)="doReset();"></button>&nbsp;
    <button pButton type="button" label="New Leads" (click)="doCall(0)" [disabled]="cb1" ></button>&nbsp;
    <button pButton type="button" label="Second Call" (click)="doCall(1)" [disabled]="cb2" ></button>&nbsp;
    <button pButton type="button" label="Third Call" (click)="doCall(2)" [disabled]="cb3" ></button>&nbsp;
    <button pButton type="button" label="Call Back" (click)="doCall(8)" [disabled]="cb4" ></button>&nbsp;
    <button pButton type="button" label="Bookings" icon="fa-plus" (click)="doBookings()"></button>&nbsp;
    <button pButton type="button" label="Clients" icon="fa-plus" (click)="doClients()"></button>&nbsp;
    <button *ngIf="RepView" pButton type="button" label="Reports" icon="fa-plus" (click)="doReports()" class="p-button-secondary"></button>&nbsp;
</p-menubar>
<p-table #dt [value]="Clients" [columns]="cols" [lazy]="true" (onLazyLoad)="doClientSearch($event)" [paginator]="true" 
    [rows]="10" [totalRecords]="TotalClientRecords" [loading]="loading" selectionMode="single" [(selection)]="SelectedClient" dataKey="id" [responsive]="true" [rowsPerPageOptions]="[10,50,100,250,500]" 
    stateStorage="session" stateKey="newleads-search-table">
    <ng-template pTemplate="header">
        <tr>
			<th style="width:40px;"></th>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th>
                Imported From
                <p-sortIcon [field]="wherefrom"></p-sortIcon>
            </th>
			<th>
                Imported
                <p-sortIcon [field]="dt_created"></p-sortIcon>
            </th>
        </tr>
        <tr>
			<th></th>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
               <input *ngSwitchCase="'clientname'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
               <input *ngSwitchCase="'phone'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
               <input *ngSwitchCase="'address'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
               <input *ngSwitchCase="'postcode'" pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
            </th>
            <th></th>
            <th></th>
        </tr>        
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr  [pSelectableRow]="rowData" (dblclick)="onRowDblClick($event, rowData)">
			<td style="width:40px;">{{i+1}}</td>
            <td *ngFor="let col of cols">
                
                    {{rowData[col.field]}}
            </td>
            <td>{{rowData['wherefrom']}} </td>
            <td>{{rowData['dt_created'] | date}}, {{rowData['dt_created'] | date:'shortTime'}}</td>
        </tr>
    </ng-template>
</p-table>
