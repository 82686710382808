<app-searchtoolbar></app-searchtoolbar>
<p-toolbar>
	<div class="p-toolbar-group-left">
		<strong>Transaction Date:</strong><p-calendar [(ngModel)]="TransactionDate" dateFormat="dd/mm/yy"></p-calendar>&nbsp;&nbsp;&nbsp;
		<strong>Authorization Code:</strong><input type="text" pInputText [(ngModel)]="AuthorizationCode"/>&nbsp;&nbsp;&nbsp;
		<button pButton label="Search" (click)="doLoad()" class="p-button-secondary"></button>
	</div>
</p-toolbar>
<p-table #dt [value]="Clients" [columns]="cols" [totalRecords]="TotalClientRecords" selectionMode="single" [(selection)]="SelectedClient" dataKey="ref">
    <ng-template pTemplate="header">
        <tr>
          <th style="width:40px;">&nbsp;</th>
          <th *ngFor="let col of cols">
            {{col.header}}
          </th>
          <th style="width:20px;">&nbsp;</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr  [pSelectableRow]="rowData" (dblclick)="onRowDblClick($event, rowData)">
			<td style="width:40px;">{{i+1}}</td>
            <td *ngFor="let col of cols">
                    {{rowData[col.field]}}
            </td>
			<td style="width:20px;"></td>
        </tr>
    </ng-template>
</p-table>