import { Component, OnInit } from '@angular/core';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';

@Component({
  selector: 'app-productsmanager',
  templateUrl: './productsmanager.component.html',
  styleUrls: ['./productsmanager.component.css']
})
export class ProductsmanagerComponent implements OnInit {

  constructor( private sysmessage: MessageService, private rpc: RpcService ) { }

  // Lookups
  product_types: any[] = [];
  product_suppliers: any[] = [];

  // Form Bindings
  showform: boolean = false;
  productuuid: String = "";
  supplieruuid: String = "";
  productcode: String = "";
  producttype: String = "";
  productname: String = "";
  product_description: String = "";

  // Table Setup
  ProductCols: any[] = [];

  // Table Records
  Products: any; // Records
  SelectedProduct: any; // Selected Record
  TotalProductRecords: number; // Number of Records
  ProductSearchEvent: any; // Search Record Filter



  ngOnInit(): void {

    this.ProductCols = [
      { field: 'producttype', header: 'Type' },
      { field: 'productcode', header: 'Code' },
      { field: 'productname', header: 'Name' },
      { field: 'product_description', header: 'Description' },
      { field: 'stock_level', header: 'No. In Stock' },
    ];

    this.rpc.doGet('products/typelookups').subscribe((data: any)=>{ this.product_types = data.result; });
    this.rpc.doGet('suppliers/lookups').subscribe((data: any)=>{ this.product_suppliers = data.result; });


  }

  doProductSearch(event){

    this.ProductSearchEvent = event;

    this.rpc.doPost('products/list', event).subscribe((data: any)=>{
      this.TotalProductRecords = data.result.total;
      this.Products = data.result.records;

    }, (error)=>{
      this.Products = [];
      this.TotalProductRecords = 0;
      this.sysmessage.add({severity:'error', summary: 'Products List', detail:'List of products could not be fetched'});
    });


  }

  doNewProduct(txt){
    this.productuuid = "";
    this.supplieruuid = "";
    this.productcode = "";
    this.producttype = "";
    this.productname = "";
    this.product_description = "";
    this.showform = true;

  }

  doEditProduct(event, rowData){
    this.productuuid = rowData['uuid'];
    this.supplieruuid = rowData['supplieruuid'];
    this.productcode = rowData['productcode'];
    this.producttype = rowData['producttype'];
    this.productname = rowData['productname'];
    this.product_description = rowData['product_description'];

    this.showform = true;
  }


  doSave(){
    // Collect Information
    let dat = { uuid: this.productuuid, supplieruuid: this.supplieruuid, productcode: this.productcode, producttype: this.producttype,
      productname: this.productname, product_description: this.product_description }

    this.rpc.doPost('products/save', dat).subscribe((data: any)=>{

       this.sysmessage.add({severity:'info', summary: 'Product Details', detail:'Product details have been saved'});

       this.showform = false;

       this.doProductSearch(this.ProductSearchEvent);

    }, (error)=>{

       this.sysmessage.add({severity:'error', summary: 'Product Details', detail:'Product details have not been saved'});

    });      

  }
}
