import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';
import { Observable, Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import SignaturePad from 'signature_pad';

// Webcam
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
  selector: 'app-repcontract',
  templateUrl: './repcontract.component.html',
  styleUrls: ['./repcontract.component.css']
})
export class RepcontractComponent implements OnInit {

  // Webcam 
  @Output() getPicture = new EventEmitter<WebcamImage>();
  showWebcam: boolean = true;
  isCameraExist: boolean = true;
  tpdisplay: boolean;
  errors: WebcamInitError[] = [];
  doc_type: string;
  doc_types: Array<any> = [];


  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  deviceInfo = null;

  // Signatures
  signaturePad: SignaturePad;
  signaturePad_cust_pay: SignaturePad;
  signaturePad_cust_sig: SignaturePad;
  @ViewChild('repsig') canvasEl: ElementRef;
  @ViewChild('custpaysig') canvasCustPay: ElementRef;
  @ViewChild('custsig') canvasCustSig: ElementRef;
  signatureImg: string;



  bookid: number;

  subroute: any;

  // REP
  repid: string;
  repname: string;

  // CLIENT
  clientid: number;
  clientname: string;
  clientaddress: string;
  clientphone: string;
  clientemail: string;

  // DOCUMENTS
  docpics: Array<{type: string, pic: any}>;

  // PRODUCTS: Blinds
  addblinddlg: boolean = false;
  blind_product: string;
  blind_colour: string;
  blind_type: string;
  blind_types: Array<any>;
  blind_room: string;
  blind_tape: string;
  blind_tapes: Array<any>;
  blind_width: number;
  blind_height: number;
  blinds: Array<{product: string, colour: string, type: string, room: string, tape: string, width: number, height: number}> = [];

  // PRODUCTS: Flooring
  addflooringdlg: boolean = false;
  floor_product: string;
  floor_room: string;
  floor_size: string;
  floor_type: string;
  floor_types: Array<any> = [];
  floor_underlay: string;
  floor_gripper: string;
  floor_beading: string;
  floors: Array<{product: string, room: string, size: string, type: string, underlay: string, gripper: string, beading: string}> = [];

  // PRODUCTS: Beds
  addbeddlg: boolean = false;
  bed_type: string;
  bed_colour: string;
  bed_mattress: string;
  bed_diamonds: string;
  bed_buttons: string;
  bed_size: string;
  bed_sizes: Array<any> = [];
  beds: Array<{ type: string, colour: string, mattress: string, diamonds: string, buttons: string, size: string}> = [];


  // PRODUCTS: Furniture
  addfurnituredlg: boolean = false;
  furniture_type: string;
  furniture_types: Array<any> = [];
  diningroom_table: string;
  diningroom_chaircount: string;
  diningroom_chaircolour: string;
  otherroom_product: string;
  otherroom_colour: string;
  otherroom_items: string;
  furnitures: Array<{type: string, dining_table: string, dining_chairs: string, dining_chairs_colour: string, other_room_product: string, other_room_colour: string, other_room_items: string}> = [];
  // OLD

  // Paid on day
  fitting_price: string;
  delivery: string;
  build: string;
  price_pod: string;

  note: string;

  deposit: string;
  payments_per: string;
  payments_per_list: Array<any> = [];
  payments_per_amount: string;
  total: string;
  pay_first: string;
  days_list: Array<any> = [];

  iconfirm: boolean;

  // CONTRACT
  contractdate: Date = new Date();

  cansave: boolean = true;

  constructor(private sysmessage: MessageService, private rpc: RpcService, private router: Router, private routeparams: ActivatedRoute, private _location: Location, private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {

    this.deviceInfo = this.deviceService.getDeviceInfo();

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();

    // Webcam init
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.isCameraExist = mediaDevices && mediaDevices.length > 0;
    });

  
    this.docpics = [];

    // Blinds
    this.blind_product = null;
    this.blind_type = null;
    this.blind_colour = null;
    this.blind_types = [];
    this.blind_types.push({label: 'SELECT', value: null});
    this.blind_types.push({label: 'FAUXWOOD', value: 'FAUXWOOD'});
    this.blind_types.push({label: 'ROLLER', value: 'ROLLER'});
    this.blind_types.push({label: 'VENETIAN', value: 'VENETION'});
    this.blind_types.push({label: 'VERTICAL', value: 'VERTICAL'});
    this.blind_tapes = [];
    this.blind_tapes.push({label: 'NO', value: 'NO'});
    this.blind_tapes.push({label: 'YES', value: 'YES'});

    //Furniture
    this.furniture_types.push({label: 'DINING ROOM', value: 'DINING ROOM'});
    this.furniture_types.push({label: 'BEDROOM / LIVING ROOM', value: 'BEDROOM / LIVING ROOM'});

    // Beds
    this.bed_sizes.push({label: 'SINGLE', value: 'SINGLE'});
    this.bed_sizes.push({label: 'DOUBLE', value: 'DOUBLE'});
    this.bed_sizes.push({label: 'SMALL DOUBLE', value: 'SMALL DOUBLE'});
    this.bed_sizes.push({label: 'KING', value: 'KING'});
    this.bed_sizes.push({label: 'SUPERKING', value: 'SUPERKING'});

    // Floors
    this.floor_types.push({label: 'CARPET', value: 'CARPET'});
    this.floor_types.push({label: 'LAMINATE', value: 'LAMINATE'});
    this.floor_types.push({label: 'VINYL', value: 'VINYL'});

    this.doc_types.push({label: 'PASSPORT', value: 'PASSPORT'});
    this.doc_types.push({label: 'DRIVING LICENSE', value: 'DRIVING LICENSE'});
    this.doc_types.push({label: 'BIRTH CERTIFICATE', value: 'BIRTH CERTIFICATE'});
    this.doc_types.push({label: 'PROOF OF ADDRESS', value: 'PROOF OF ADDRESS'});
    this.doc_types.push({label: 'UTILITY BILL', value: 'UTILITY BILL'});
    this.doc_types.push({label: 'BANK STATEMENT', value: 'BANK STATEMENT'});

    this.payments_per_list.push({label: 'SELECT', value: null})
    this.payments_per_list.push({label: 'WEEKLY', value: 'WEEKLY'})
    this.payments_per_list.push({label: 'FORTNIGHTLY', value: 'FORTNIGHTLY'})
    this.payments_per_list.push({label: '28 DAYS', value: '28 DAYS'})
    this.payments_per_list.push({label: 'MONTHLY', value: 'MONTHLY'})


    this.days_list.push({label: 'SELECT', value: null})
    this.days_list.push({label: 'MONDAY', value: 'MONDAY'})
    this.days_list.push({label: 'TUESDAY', value: 'TUESDAY'})
    this.days_list.push({label: 'WEDNESDAY', value: 'WEDNESDAY'})
    this.days_list.push({label: 'THURSDAY', value: 'THURSDAY'})
    this.days_list.push({label: 'FRIDAY', value: 'FRIDAY'})
    this.days_list.push({label: 'SATURDAY', value: 'SATURDAY'})
    this.days_list.push({label: 'SUNDAY', value: 'SUNDAY'})


    this.subroute = this.routeparams.params.subscribe(params => {
      
      this.bookid = +params['id'];

      // Trigers Loading
    	this.doLoad();

    });  



  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad_cust_pay = new SignaturePad(this.canvasCustPay.nativeElement);
    this.signaturePad_cust_sig = new SignaturePad(this.canvasCustSig.nativeElement);
  }

  notify(typ, summ, det){
    this.sysmessage.add({severity:typ, summary: summ, detail:det});
  }


// LOADING BASIC DETAILS:
  doLoad(){

    let dat = { id: this.bookid };

    this.rpc.doPost('reps/signupfetch', dat).subscribe((data: any)=>{

        console.log(data);
      
      // Rep
      this.repid = data.result.rep.id;
      this.repname = data.result.rep.name;

      // Client
      this.clientid = data.result.client.id;
      this.clientname = data.result.client.name;
      this.clientaddress = data.result.client.address;
      this.clientphone = data.result.client.phone;
      this.clientemail = data.result.client.email;

      // Documents




      //this.notify('info','Client Details','Client details have been updated');

    }, (error)=>{
      this.notify('error','Booking Details',error);
      this.doBack();
    });      
    
    
  }


// START WEBCAM CODE
  doOpenPic(){ this.showWebcam = true; this.tpdisplay = true; }

  takeSnapshot(): void { this.trigger.next(); }

  onOffWebCame() { this.showWebcam = !this.showWebcam; }

  handleInitError(error: WebcamInitError) { this.errors.push(error); }

  changeWebCame(directionOrDeviceId: boolean | string) { this.nextWebcam.next(directionOrDeviceId); }

  handleImage(webcamImage: WebcamImage) { this.docpics.push({type: this.doc_type, pic: webcamImage.imageAsDataUrl }); this.showWebcam = false; this.tpdisplay = false; }

  get triggerObservable(): Observable<void> { return this.trigger.asObservable(); }

  get nextWebcamObservable(): Observable<boolean | string> { return this.nextWebcam.asObservable(); }

// END WEBCAM CODE

  doSignup(){
    
    this.notify('error','Sign up','Failed to sign up! Missing Details');

    // BUILD UP AND POST.
    
  }


  openAddBlind(){
    this.blind_room = null;
    this.blind_tape = 'NO';
    this.blind_width = null;
    this.blind_height = null;
    this.addblinddlg = true;
  }

  AddBlind(){

    this.blinds.push({
      product: this.blind_product,
      colour: this.blind_colour,
      type: this.blind_type,
      room: this.blind_room,
      tape: this.blind_tape,
      width: this.blind_width,
      height: this.blind_height
    });

    this.addblinddlg = false;
  }

  RemoveBlind(i){ this.blinds.splice(i, 1); }

  openAddFurniture(){
    this.furniture_type = 'DINING ROOM';
    this.diningroom_table = null;
    this.diningroom_chaircount = null;
    this.diningroom_chaircolour = null;
    this.otherroom_product = null;
    this.otherroom_items = null;
    this.otherroom_colour = null;
    this.addfurnituredlg = true;
  }

  AddFurniture(){

    this.furnitures.push({
      type: this.furniture_type,
      dining_table: this.diningroom_table,
      dining_chairs: this.diningroom_chaircount,
      dining_chairs_colour: this.diningroom_chaircolour,
      other_room_product: this.otherroom_product,
      other_room_colour: this.otherroom_colour,
      other_room_items: this.otherroom_items});

    this.addfurnituredlg = false;
  }

  RemoveFurniture(i){ this.furnitures.splice(i, 1); }

  openAddBed(){

    this.bed_type = null;
    this.bed_colour = null;
    this.bed_mattress = null;
    this.bed_diamonds = "NO";
    this.bed_buttons = "NO";
    this.bed_size = "SINGLE";

    this.addbeddlg = true;
  }

  AddBed(){

    this.beds.push({
      type: this.bed_type,
      colour: this.bed_colour,
      mattress: this.bed_mattress,
      diamonds: this.bed_diamonds,
      buttons: this.bed_buttons,
      size: this.bed_size});

    this.addbeddlg = false;
  }

  RemoveBed(i){ this.beds.splice(i, 1); }

  openAddFloor(){

    this.floor_product = null;
    this.floor_room = null;
    this.floor_size = null;
    this.floor_type = "CARPET";
    this.floor_underlay = "NO";
    this.floor_beading = "NO";
    this.floor_gripper = "NO";

    this.addflooringdlg = true;

  }

  AddFloor(){
  
  this.floors.push({
    product: this.floor_product,
    room: this.floor_room,
    size: this.floor_size,
    type: this.floor_type,
    underlay: this.floor_underlay,
    gripper: this.floor_gripper,
    beading: this.floor_beading});

    this.addflooringdlg = false;

  }

  RemoveFloor(i){ this.floors.splice(i, 1); }

  RemovePic(i){ this.docpics.splice(i, 1); }


  // Signatures
  startDrawing(event: Event) {
    console.log(event);
    // works in device not in browser

  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearrepsigPad() {
    this.signaturePad.clear();
  }

  clearcustpayPad() {
    this.signaturePad_cust_pay.clear();
  }
  clearcustsigPad() {
    this.signaturePad_cust_sig.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }  


  doBack(){
    setTimeout(()=>{
      this._location.back();
    }, 50);    
  }

}
