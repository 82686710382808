<div class="p-grid" style="padding: 5px;">
	<div class="p-col-12">
		<p-panel header="New Order">
			
			<table class="customTable">
				<tr>
					<th style = "width: 15%;">REP:</th>
					<td>{{ repname }}</td>
				</tr>
				<tr>
					<th>CONTRACT DATE:</th>
					<td>{{contractdate | date}}</td>
				</tr>
			</table>

		</p-panel>
	</div>

	<div class="p-col-12">
		<p-panel header="Client's Details">
			
			<table class="customTable">
				<tr>
					<th style="width: 15%;">CUSTOMER NAME:</th>
					<td>{{clientname}}</td>
				</tr>
				<tr>
					<th>CUSTOMER ADDRESS:</th>
					<td><p [innerHtml]="clientaddress"></p></td>
				</tr>
				<tr>
					<th>CUSTOMER PHONE NO:</th>
					<td>{{clientphone}}</td>
				</tr>
				<tr>
					<th>EMAIL:</th>
					<td>{{clientemail}}</td>
				</tr>
			</table>

		</p-panel>
	</div>

	<div class="p-col-12">
		
		<p-panel header="Identification Documents">
			<ng-template pTemplate="icons">
            	<button pButton class="p-button-success" (click)="doOpenPic()">
                	Take Picture<span class="pi pi-camera"></span>
            	</button>
		    </ng-template>

			<table class="customTable">
				<tr>
					<th style="width: 15%;">DOCUMENT:</th>
					<th>PHOTO:</th>
					<th style="width: 50px;">&nbsp;</th>
				</tr>
				<ng-template #elseTemplate>
					<tr>
						<td colspan="3">No Photo's Taken!</td>
					</tr>
				</ng-template>
				<ng-container *ngIf="docpics.length > 0; else elseTemplate">
					<tr *ngFor="let c of docpics; index as i">
						<td>{{ c.type }}</td>
						<td><img src="{{ c.pic }}"></td>
						<td><button pButton type="button" class="p-button-danger" label="X" (click)="RemovePic(i)"></button></td>
					</tr>
				</ng-container>
			</table>

		</p-panel>

	</div>

	<div class="p-col-12">
		
		<p-panel header="Add Products">

			<ng-template pTemplate="icons">
            	<button pButton class="p-button-success" label="Flooring" icon="pi pi-plus" (click)="openAddFloor()"></button>&nbsp;
            	<button pButton class="p-button-success" label="Bed" icon="pi pi-plus" (click)="openAddBed()"></button>&nbsp;
            	<button pButton class="p-button-success" label="Blinds" icon="pi pi-plus" (click)="openAddBlind()"></button>&nbsp;
            	<button pButton class="p-button-success" label="Furniture" icon="pi pi-plus" (click)="openAddFurniture()"></button>
		    </ng-template>			

			<table class="customTable">
				<tr>
					<th style="width: 15%;">TYPE:</th>
					<th>PRODUCT:</th>
					<th style="width: 50px;">&nbsp;</th>
				</tr>
				<ng-template #elseNoProductsTemplate>
					<tr>
						<td colspan="3">No Products Added!</td>
					</tr>
				</ng-template>
				<ng-container *ngIf="floors.length > 0 || beds.length > 0 || furnitures.length > 0 || blinds.length > 0 ; else elseNoProductsTemplate">

				<tr *ngFor="let c of floors; index as i">
					<td>FLOOR</td>
					<td>
						<table class="customTable">
							<tr><td style="width: 15%;">Room:</td><td>{{ c.room }}</td></tr>
							<tr><td>Size:</td><td>{{ c.size }}</td></tr>
							<tr><td>Product:</td><td>{{ c.product }}</td></tr>
							<tr><td>Underlay:</td><td>{{ c.underlay }}</td></tr>
							<tr><td>Gripper:</td><td>{{ c.gripper }}</td></tr>
							<tr><td>Beading:</td><td>{{ c.beading }}</td></tr>
						</table>
					</td>
					<td><button pButton type="button" class="p-button-danger" label='X' (click)="RemoveFloor(i)"></button></td>
				</tr>

				<tr *ngFor="let c of beds; index as i">
					<td>BED</td>
					<td>
						<table class="customTable">
							<tr><td style="width: 15%;">Type:</td><td>{{ c.type }}</td></tr>
							<tr><td>Colour:</td><td>{{ c.colour }}</td></tr>
							<tr><td>Mattress:</td><td>{{ c.mattress }}</td></tr>
							<tr><td>Diamonds:</td><td>{{ c.diamonds }}</td></tr>
							<tr><td>Buttons:</td><td>{{ c.buttons }}</td></tr>
							<tr><td>Size:</td><td>{{ c.size }}</td></tr>
						</table>
					</td>
					<td><button pButton type="button"  class="p-button-danger" label='X' (click)="RemoveBed(i)"></button></td>
				</tr>

				<tr *ngFor="let c of blinds; index as i">
					<td>BLIND</td>
					<td>
						<table class="customTable">
							<tr><td style="width: 15%;">Product:</td><td>{{ c.product }}</td></tr>
							<tr><td>Colour:</td><td>{{ c.colour }}</td></tr>
							<tr><td>Type:</td><td>{{ c.type }}</td></tr>
							<tr><td>Room:</td><td>{{ c.room }}</td></tr>
							<tr><td>Tape:</td><td>{{ c.tape }}</td></tr>
							<tr><td>Width:</td><td>{{ c.width }}</td></tr>
							<tr><td>Height:</td><td>{{ c.height }}</td></tr>
						</table>
					</td>
					<td><button pButton type="button" class="p-button-danger" label='X' (click)="RemoveBlind(i)"></button></td>
				</tr>

				<tr *ngFor="let c of furnitures; index as i">
					<td>FUNRITURE</td>
					<td>
						<table class="customTable">
							<tr><td style="width: 15%;">Location:</td><td>{{ c.type }}</td></tr>

							<ng-template #elseNotDiningRoomTemplate>
								<tr><td>Product Name:</td><td>{{ c.other_room_product }}</td></tr>
								<tr><td>Colour:</td><td>{{ c.other_room_colour }}</td></tr>
								<tr><td>Items:</td><td>{{ c.other_room_items }}</td></tr>
							</ng-template>
							<ng-container *ngIf="c.type == 'DINING ROOM' ; else elseNotDiningRoomTemplate">
								<tr><td>Table Name:</td><td>{{ c.dining_table }}</td></tr>
								<tr><td>Number of chairs:</td><td>{{ c.dining_chairs }}</td></tr>
								<tr><td>Colour of chairs:</td><td>{{ c.dining_chairs_colour }}</td></tr>
							</ng-container>
						</table>
					</td>
					<td><button pButton type="button" label='X' (click)="RemoveFurniture(i)"></button></td>
				</tr>
				</ng-container>
			</table>

		</p-panel>

	</div>

	<div class="p-col-12">

		<p-panel header="Payments on Day"><br>

			<div class="p-grid">
				<div class="p-col-2">Fitting Price <b>Paid on Day</b></div>
				<div class="p-col-10"><p-inputNumber [(ngModel)]="fitting_price" mode="currency" currency="GBP" locale="en-GB"  [style]="{width: '100%'}"></p-inputNumber></div>
			</div>
			<p-divider></p-divider>
			<div class="p-grid">
				<div class="p-col-2">Delivery</div>
				<div class="p-col-10"><p-dropdown [options]="blind_tapes" [(ngModel)]="delivery" appendTo="body"></p-dropdown></div>
				<div class="p-col-2">Build</div>
				<div class="p-col-10"><p-dropdown [options]="blind_tapes" [(ngModel)]="build" appendTo="body"></p-dropdown></div>
				<div class="p-col-2">Price <b>Paid on Day</b></div>
				<div class="p-col-10"><p-inputNumber [(ngModel)]="price_pod" mode="currency" currency="GBP" locale="en-GB"  [style]="{width: '100%'}"></p-inputNumber></div>
			</div>

		</p-panel>

	</div>

	<div class="p-col-12">

		<p-panel header="Notes">

			<div class="p-grid">
				<div class="p-col-12">
					<textarea [rows]="10" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="note"></textarea>
				</div>
			</div>

		</p-panel>

	</div>

	<div class="p-col-12">

		<p-panel header="Product(s) Payments">

			<div class="p-grid">
				<div class="p-col-2">Deposit</div>
				<div class="p-col-10"><p-inputNumber [(ngModel)]="deposit" mode="currency" currency="GBP" locale="en-GB"  [style]="{width: '100%'}"></p-inputNumber></div>
				<div class="p-col-2">Payments Per</div>
				<div class="p-col-10"><p-dropdown [options]="payments_per_list" [(ngModel)]="payments_per" appendTo="body"></p-dropdown></div>
				<div class="p-col-2">Amount</div>
				<div class="p-col-10"><p-inputNumber [(ngModel)]="payments_per_amount" mode="currency" currency="GBP" locale="en-GB"  [style]="{width: '100%'}"></p-inputNumber></div>
				<div class="p-col-12"><p-divider></p-divider></div>
				<div class="p-col-2">Total</div>
				<div class="p-col-10"><p-inputNumber [(ngModel)]="total" mode="currency" currency="GBP" locale="en-GB"  [style]="{width: '100%'}"></p-inputNumber></div>
				<div class="p-col-12"><p-divider></p-divider></div>
				<div class="p-col-2">1st Payment</div>
				<div class="p-col-10"><p-dropdown [options]="days_list" [(ngModel)]="pay_first" appendTo="body"></p-dropdown></div>
			</div>

		</p-panel>

	</div>

	<div class="p-col-12">

		<p-panel header="Acknowledgements">

			<div class="p-grid">
				<div class="p-col-12">&nbsp;</div>
				<div class="p-col-9 p-md-6 p-lg-6">I hereby acknowledge that I have read and understood the terms and conditions and that any paid deposit is non-refundable.</div>
				<div class="p-col-3 p-md-6 p-lg-6">
					<p-toggleButton [(ngModel)]="iconfirm" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
				</div>
				<div class="p-col-12"><p-divider></p-divider></div>
				<div class="p-col-9 p-md-6 p-lg-6">I hereby give permission for Easy2Pay to take payment as agreed until the balance is paid in full.</div>
				<div class="p-col-3 p-md-6 p-lg-6">
					<canvas #custpaysig ></canvas>
					<button pButton type="button" (click)="clearcustpayPad()">Clear</button>
				</div>
				<div class="p-col-12 p-md-12 p-lg-12"><p-divider></p-divider></div>
				<div class="p-col-9 p-md-6 p-lg-6">CUSTOMER SIGNATURE</div>
				<div class="p-col-3 p-md-6 p-lg-6">
					<canvas #custsig ></canvas>
					<button pButton type="button" (click)="clearcustsigPad()">Clear</button>
				</div>
				<div class="p-col-12 p-md-12 p-lg-12"><p-divider></p-divider></div>
				<div class="p-col-9 p-sm-6 p-md-6 p-lg-6">REP SIGNATURE</div>
				<div class="p-col-3 p-sm-6 p-md-6 p-lg-6">
					<canvas #repsig ></canvas>
					<button pButton type="button" (click)="clearrepsigPad()">Clear</button>
				</div>
			</div>
		</p-panel>


	</div>


</div>


<div class="p-grid">
	<div class="p-col-12 p-md-12 p-lg-2"><button pButton type="button" label='Complete' class="p-button-success" (click)="doSignup()" style="width: 100%"></button></div>
	<div class="p-col-12 p-md-12 p-lg-2"><button pButton type="button" label='Cancel' (click)="doBack()" style="width: 100%"></button></div>
</div>



<p-dialog header="Take Picture" [(visible)]="tpdisplay" (onHide)="showWebcam = false;" [modal] = "true">
	<div *ngIf="isCameraExist; else noCameraExist">
    	<div style="text-align:center">
    <webcam [height]="480" [width]="480" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
            *ngIf="showWebcam" [switchCamera]="nextWebcamObservable" (initError)="handleInitError($event)"></webcam>
	    </div>

    	<div *ngIf="errors.length > 0">
        	<h4>Error Messages:</h4>
        	<ul *ngFor="let error of errors">
            	<li>{{ error | json }}</li>
        	</ul>
    	</div>
	</div>
	<ng-template #noCameraExist>
    	Camera device not available
	</ng-template>
	<p-footer>Document Type: <p-dropdown [options]="doc_types" [(ngModel)]="doc_type" appendTo="body" [style]="{'width': '200px'}"></p-dropdown> | <button pButton type="button" label="Take Picture" (click)="takeSnapshot()" [disabled]="!cansave"></button></p-footer>
</p-dialog>

<p-dialog header="Add Blinds" [(visible)]="addblinddlg" [modal] = "true" [style] = "{width: '75vw'}">
	<div class="p-grid">
		
		<div class="p-col-3"><label class="lbl-right">Product Name</label></div>
		<div class="p-col-9"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "blind_product"></div>
		<div class="p-col-3"><label class="lbl-right">Colour</label></div>
		<div class="p-col-9"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "blind_colour"></div>
		<div class="p-col-3"><label class="lbl-right">Type</label></div>
		<div class="p-col-9"><p-dropdown [options]="blind_types" [(ngModel)]="blind_type" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		<div class="p-col-3"><label class="lbl-right">Room</label></div>
		<div class="p-col-9"><input type="text" pInputText style="width: 100%;"[(ngModel)] = "blind_room"></div>
		<div class="p-col-3"><label class="lbl-right">Tape</label></div>
		<div class="p-col-9"><p-dropdown [options]="blind_tapes" [(ngModel)]="blind_tape" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		<div class="p-col-3"><label class="lbl-right">Width</label></div>
		<div class="p-col-3"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "blind_width"></div>
		<div class="p-col-3"><label class="lbl-right">Height</label></div>
		<div class="p-col-3"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "blind_height"></div>
		
	</div>

	<p-footer><button pButton type="button" label="Save" (click)="AddBlind();"></button></p-footer>

</p-dialog>

<p-dialog header="Add Bed" [(visible)]="addbeddlg" [modal] = "true" [style] = "{width: '75vw'}">
	<div class="p-grid">
		<div class="p-col-3"><label class="lbl-right">Type</label></div>
		<div class="p-col-9"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "bed_type"></div>
		<div class="p-col-3"><label class="lbl-right">Colour</label></div>
		<div class="p-col-9"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "bed_colour"></div>
		<div class="p-col-3"><label class="lbl-right">Mattress</label></div>
		<div class="p-col-9"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "bed_mattress"></div>
		<div class="p-col-3"><label class="lbl-right">Diamonds</label></div>
		<div class="p-col-9"><p-dropdown [options]="blind_tapes" [(ngModel)]="bed_diamonds" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		<div class="p-col-3"><label class="lbl-right">Buttons</label></div>
		<div class="p-col-9"><p-dropdown [options]="blind_tapes" [(ngModel)]="bed_buttons" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		<div class="p-col-3"><label class="lbl-right">Size</label></div>
		<div class="p-col-9"><p-dropdown [options]="bed_sizes" [(ngModel)]="bed_size" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
	</div>
	
	<p-footer><button pButton type="button" label="Save" (click)="AddBed();"></button></p-footer>
</p-dialog>

<p-dialog header="Add Flooring" [(visible)]="addflooringdlg" [modal] = "true" [style] = "{width: '75vw'}">
	<div class="p-grid">
		<div class="p-col-3"><label class="lbl-right">Room</label></div>
		<div class="p-col-9"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "floor_room"></div>
		<div class="p-col-3"><label class="lbl-right">Size</label></div>
		<div class="p-col-9"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "floor_size"></div>
		<div class="p-col-3"><label class="lbl-right">Type</label></div>
		<div class="p-col-9"><p-dropdown [options]="floor_types" [(ngModel)]="floor_type" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		<div class="p-col-3"><label class="lbl-right">Underlay</label></div>
		<div class="p-col-9"><p-dropdown [options]="blind_tapes" [(ngModel)]="floor_underlay" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		<div class="p-col-3"><label class="lbl-right">Gripper</label></div>
		<div class="p-col-9"><p-dropdown [options]="blind_tapes" [(ngModel)]="floor_gripper" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		<div class="p-col-3"><label class="lbl-right">Beading</label></div>
		<div class="p-col-9"><p-dropdown [options]="blind_tapes" [(ngModel)]="floor_beading" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		

	</div>
	<p-footer><button pButton type="button" label="Save" (click)="AddFloor();"></button></p-footer>
</p-dialog>

<p-dialog header="Add Furniture" [(visible)]="addfurnituredlg" [modal] = "true" [style] = "{width: '75vw'}">
	<div class="p-grid">
		
		<div class="p-col-3"><label class="lbl-right">Location</label></div>
		<div class="p-col-9"><p-dropdown [options]="furniture_types" [(ngModel)]="furniture_type" appendTo="body" [style]="{'width': '200px'}"></p-dropdown></div>
		<div class="p-col-3" *ngIf="furniture_type == 'DINING ROOM'"><label class="lbl-right">Table Name</label></div>
		<div class="p-col-9" *ngIf="furniture_type == 'DINING ROOM'"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "diningroom_table"></div>
		<div class="p-col-3" *ngIf="furniture_type == 'DINING ROOM'"><label class="lbl-right">Number of chairs</label></div>
		<div class="p-col-9" *ngIf="furniture_type == 'DINING ROOM'"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "diningroom_chaircount"></div>
		<div class="p-col-3" *ngIf="furniture_type == 'DINING ROOM'"><label class="lbl-right">Colour of chairs</label></div>
		<div class="p-col-9" *ngIf="furniture_type == 'DINING ROOM'"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "diningroom_chaircolour"></div>
		<div class="p-col-3" *ngIf="furniture_type != 'DINING ROOM'"><label class="lbl-right">Product Name</label></div>
		<div class="p-col-9" *ngIf="furniture_type != 'DINING ROOM'"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "otherroom_product"></div>
		<div class="p-col-3" *ngIf="furniture_type != 'DINING ROOM'"><label class="lbl-right">Colour</label></div>
		<div class="p-col-9" *ngIf="furniture_type != 'DINING ROOM'"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "otherroom_colour"></div>
		<div class="p-col-3" *ngIf="furniture_type != 'DINING ROOM'"><label class="lbl-right">Items</label></div>
		<div class="p-col-9" *ngIf="furniture_type != 'DINING ROOM'"><input type="text" pInputText style="width: 100%;" [(ngModel)] = "otherroom_items"></div>
		
	</div>
	<p-footer><button pButton type="button" label="Save" (click)="AddFurniture();"></button></p-footer>
</p-dialog>