import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-rsdetail',
  templateUrl: './rsdetail.component.html',
  styleUrls: ['./rsdetail.component.css']
})
export class RsdetailComponent implements OnInit {

	reptype: any;
	repsub: any;
  subroute: any;

	cols: any[];
	loading: boolean;
  canscroll: boolean = false;

  ListEvent: any;

  ListData: any;
  SelectedClient: any;
  TotalListRecords: number;

  constructor(private _rpc: RpcService, private _router: Router, private _routeparams: ActivatedRoute, private _location: Location) {

  	this.cols = [
  		{ field: 'clientid', header: 'Client ID', filterMatchMode: 'contains' },
  		{ field: 'clientname', header: 'Name' },
  		{ field: 'total', header: 'Total' },
  		{ field: 'balance', header: 'Balance' },
  		{ field: 'repeat_amount', header: 'Taking' },
  		{ field: 'repeat_every', header: 'Every' },
  		{ field: 'repeat_next', header: 'Next' },
  		{ field: 'repeat_wait', header: 'Next Try' },
  		{ field: 'repeat_days', header: 'Day' },
		{ field: 'status', header: 'Status' },

  	];

  }

  ngOnInit() {

    this.subroute = this._routeparams.params.subscribe(params => {
       this.reptype = params['typ']; // (+) converts string 'id' to a number
       this.repsub = params['subtyp'];
       console.log(this.reptype);
       console.log(this.repsub);

    });

  }

  doListing($event){
  	this.ListEvent = $event;
  	this.ListEvent.reporttype = this.reptype;
  	this.ListEvent.reportsubtype = this.repsub;

  	//this.loading = true;

    this._rpc.doPost('reports/detail', this.ListEvent).subscribe((data: any)=>{
      this.TotalListRecords = data.result.total;
      this.ListData = data.result.records;
      if(data.records.length > 7){
        this.canscroll = true;
      } else {
        this.canscroll = false;
      }

      //this.cache.store('clientsearch','searchtotal', data.total);
      //this.cache.store('clientsearch','searchrecords', data.records);
      //this.cache.store('clientsearch','payyesno',this.currentyesno);
      //this.cache.store('clientsearch','status',this.currentstatus);

      //this.loading = false;

    });  	

  }

  doLoadClient($event, rowData){

	setTimeout(()=>{
		this._router.navigate(['/client/' + rowData.clientid]);
	}, 50);	

  }


  doBack(){
	setTimeout(()=>{
	    this._location.back();
	}, 50);
  }

  doGetTraffic(col){
	if(col==2){ return 'red'; }
	if(col==1){ return 'yellow'; }
	return 'green';
  }

  doClients(){
  setTimeout(()=>{
    this._router.navigate(['/clientsearch']);
  }, 50);
  }  

  doLeads(){
  setTimeout(()=>{
    this._router.navigate(['/newleads']);
  }, 50);
  }

}
