import { Component } from '@angular/core';
import {MessageService, ConfirmationService} from 'primeng/api';
import {MenuItem} from 'primeng/api';
import { AuthenticationService } from './_services';
import { User } from './_models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService, ConfirmationService]  
})
export class AppComponent {
  title = 'app';
  user: User;
  appmenu: MenuItem[];

  constructor(private sysmsg: MessageService, private authenticationService: AuthenticationService){
  		this.title = 'Easy 2 Pay';

      this.authenticationService.user.subscribe(x => this.user = x);
  }

  doLogout() {
        this.authenticationService.logout();
    }

}
