<p-menubar [model]="mbitems">
        <button pButton type="button" label="Clients" (click)="doClients()"></button>&nbsp;
        <button pButton type="button" label="New Leads" (click)="doLeads()"></button>&nbsp;
        <button pButton type="button" label="Bookings" (click)="doBookings()"></button>&nbsp;
        <button *ngIf="RepView" pButton type="button" label="Reports" icon="fa-plus" (click)="doReports()"></button>
</p-menubar>
<p-table #dt [value]="Clients" [columns]="cols" [totalRecords]="TotalClientRecords" selectionMode="single" [(selection)]="SelectedClient" dataKey="ref">
    <ng-template pTemplate="header">
        <tr>
          <th style="width:40px;">&nbsp;</th>
          <th *ngFor="let col of cols">
            {{col.header}}
          </th>
          <th style="width:20px;">&nbsp;</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr  [pSelectableRow]="rowData" (dblclick)="onRowDblClick($event, rowData)">
			<td style="width:40px;">{{i+1}}</td>
            <td *ngFor="let col of cols">
                    {{rowData[col.field]}}
            </td>
			<td style="width:20px;"></td>
        </tr>
    </ng-template>
</p-table>