import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';
import { Location } from '@angular/common';

import {FullCalendar} from 'primeng/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';


@Component({
  selector: 'app-bookinglist',
  templateUrl: './bookinglist.component.html',
  styleUrls: ['./bookinglist.component.css']
})
export class BookinglistComponent implements OnInit {

	@ViewChild('bookcal') private calendar: FullCalendar;
  @ViewChild('op') overlaycomp: any;
 
  currentuser: string;
  bookrep: string;
  RepView: boolean = true;

  // Selected Date;
  currentdate: string;
  displaydate: string;

  calstart: string;
  calend: string;


	reps: any[];

    events: any[];

    options: any

  // Individual Popup
  eventstartdate: string;
  eventid: number;
  eventtitle: string;
  eventname: string;
  eventdescription: string;
  eventaddress: string;
  eventphone: string;
  eventclientid: string;
  eventoverlay: boolean = false;


  // rebook
  rebook: boolean = false;
  ppRebookDisabled: boolean = true;
  bookingdate: Date;
  bookingrep: string;
  bookingnote: string;



  constructor(private sysmsg: MessageService, private rpc: RpcService, private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    if(['hb','cb','dk','developer'].indexOf(this.authenticationService.userValue.user) >= 0){
      this.RepView = true;
    } else {
      this.RepView = false;
    }



	this.bookrep = null;
    let dt = new Date();
    this.currentdate = dt.getFullYear()+ '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2);
    this.displaydate = String("00" + dt.getDate()).slice(-2) + '/' + String("00" + (dt.getMonth() + 1)).slice(-2) + '/' + dt.getFullYear();


      this.options = {
            plugins: [dayGridPlugin],
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth'
            },
            displayEventTime: false,
            height: 'auto',
            eventMouseEnter: (eventObj) => {
              this.eventid = eventObj.event.extendedProps.bookid;
              this.eventtitle = eventObj.event.title;
              this.eventstartdate = this.toSQLDate(eventObj.event.start);
              this.eventname = eventObj.event.extendedProps.clientname;
              this.eventdescription = atob(eventObj.event.extendedProps.description);
              this.eventaddress = eventObj.event.extendedProps.address;
              this.eventclientid = eventObj.event.extendedProps.clientid;
              this.eventphone = eventObj.event.extendedProps.phone;
              this.eventoverlay = true;
              this.bookingrep = eventObj.event.extendedProps.rep_id;
              this.overlaycomp.show(eventObj.jsEvent, eventObj.el);
            },
            eventMouseLeave: (eventObj, $el) => {
              this.eventoverlay = false;
              this.overlaycomp.hide();
            },
            eventClick: (e) =>  {
              setTimeout(()=>{
              //  this.router.navigate(['/client/' + this.eventclientid]);
                this.bookingdate = new Date(Date.now());
                this.bookingdate.setDate( this.bookingdate.getDate() + 1);
                this.bookingnote = "";
                this.rebook = true;

              }, 50);

            },
            datesRender: (e) => {
              setTimeout(()=>{
                let dt = new Date(e.view.currentStart);
                this.calstart = dt.getFullYear()+ '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2) + ' 00:00:00';
                dt = new Date(e.view.currentEnd);
                this.calend = dt.getFullYear()+ '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2) + ' 00:00:00';;
                this.doCalSearch();
              }, 50);              
            }
        }

	    this.rpc.doGet('lookup/reps').subscribe((data: any)=>{
    		this.reps = data.result;
    	}); 

  }


  doRebook(){

    if(this.bookingnote.length < 3){
      this.notify('error','Rebook','Please add reason for rebooking!');
      return;
    }

    let data = { oldid: this.eventid, bookon: this.toSQLDate(this.bookingdate), rep_id: this.bookingrep, note: btoa(this.bookingnote)};
    console.log(data);

   this.rpc.doPost('bookings/rebook', data).subscribe((data: any)=>{

      this.rebook = false;
      this.notify('success','Rebooked',data.result.msg);
      this.doCalSearch();

    }, (error)=>{
      this.notify('error','New Leads Update',error);
    });


  }
  
  doComplete(Flg){


    let data = { oldid: this.eventid, flg: Flg, note: btoa(this.bookingnote)};
    console.log(data);

   this.rpc.doPost('bookings/complete', data).subscribe((data: any)=>{

      this.rebook = false;
      this.notify('success','Booking',data.result.msg);
      this.doCalSearch();

    }, (error)=>{
      this.notify('error','New Leads Update',error);
    });


  }  

  notify(typ, summ, det){
    this.sysmsg.add({severity:typ, summary: summ, detail:det});
  }


  doCalSearch(){
    let data = { startdate: this.calstart, enddate: this.calend, rep: this.bookrep };

    this.rpc.doPost('bookings/list', data).subscribe((data: any)=>{
    	console.log(data);
	      this.events = data.result.records;

    });
  }

  toSQLDate(dt: Date){ 
    return dt.getFullYear() + '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2) + ' ' + String("00" + dt.getHours()).slice(-2) + ':' + String("00" + dt.getMinutes()).slice(-2) + ':' + String("00" + dt.getSeconds()).slice(-2);
  }

  //**** NAVIGATION
  doReports(){
  setTimeout(()=>{
    this.router.navigate(['/reports/summary']);
  }, 50);
  }

  doLeads(){
  setTimeout(()=>{
    this.router.navigate(['/newleads']);
  }, 50);
  }

  doClients(){
  setTimeout(()=>{
    this.router.navigate(['/clientsearch']);
  }, 50);
  }

}
