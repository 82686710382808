import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService} from 'primeng/api';
import { Location } from '@angular/common';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-reportsummary',
  templateUrl: './reportsummary.component.html',
  styleUrls: ['./reportsummary.component.css']
})
export class ReportsummaryComponent implements OnInit {

	paysumtotal: number;
	paysumdata: any[];
	paysumcols: any[];

	paysumdetailstotal: number;
	paysumdetailsdata: any[];
	paysumdetailscols: any[];

	statussumtotal: number;
	statussumdata: any[];
	statussumcols: any[];

	amountsumtotal: number;
	amountsumdata: any[];
	amountsumcols: any[];

  amountsumdetailtotal: number;
  amountsumdetaildata: any[];
  amountsumdetailcols: any[];


  constructor(private sysmsg: MessageService, private rpc: RpcService, private router: Router, private _location: Location) { }

  ngOnInit() {

  	this.paysumcols = [];
  	this.paysumcols.push({ field: 'records', header: 'Total Records' });
    this.paysumcols.push({ field: 'takeamount', header: 'Expected Amount' });
  	this.paysumcols.push({ field: 'taken', header: 'Amount Taken' });
  	this.paysumcols.push({ field: 'taken_success', header: 'Records Taken' });
  	this.paysumcols.push({ field: 'day_name', header: 'Day Taken' });
  	this.paysumcols.push({ field: 'day_date', header: 'Date Taken' });

  	this.paysumdetailscols = [];
  	this.paysumdetailscols.push({ field: 'records', header: 'Total Records' });
  	this.paysumdetailscols.push({ field: 'taken', header: 'Amount Taken' });
  	this.paysumdetailscols.push({ field: 'taken_success', header: 'Records Taken' });
  	this.paysumdetailscols.push({ field: 'paymenttype', header: 'Payment Type' });
  	this.paysumdetailscols.push({ field: 'day_name', header: 'Day Taken' });
  	this.paysumdetailscols.push({ field: 'day_date', header: 'Date Taken' });

  	this.statussumcols = [];
  	this.statussumcols.push({ field: 'records', header: 'Total Records' });
  	this.statussumcols.push({ field: 'status', header: 'Status' });
  	this.statussumcols.push({ field: 'jobs', header: 'Items' });

  	this.amountsumcols = [];
  	this.amountsumcols.push({ field: 'records', header: 'Total Records' });
  	this.amountsumcols.push({ field: 'repeat_every', header: 'Every' });
  	this.amountsumcols.push({ field: 'jobs', header: 'Items' });
  	this.amountsumcols.push({ field: 'amount', header: 'Total' });

    this.amountsumdetailcols = [];
    this.amountsumdetailcols.push({ field: 'records', header: 'Total Records' });
    this.amountsumdetailcols.push({ field: 'repeat_every', header: 'Every' });
    this.amountsumdetailcols.push({ field: 'amountper', header: 'Payment Per' });
    this.amountsumdetailcols.push({ field: 'jobs', header: 'Items' });
    this.amountsumdetailcols.push({ field: 'amount', header: 'Total' });

  }


	doPaySummary(event){
		

		this.rpc.doPost('reports/paysummary', event).subscribe((data: any)=>{
			
			this.paysumtotal = data.result.total;
			this.paysumdata = data.result.records;


    	});

  } 

  	doPaySummaryDetails(event){
		

		this.rpc.doPost('reports/paysummarydetails', event).subscribe((data: any)=>{
			
			this.paysumdetailstotal = data.result.total;
			this.paysumdetailsdata = data.result.records;

    	});

  } 

  	doStatusSummary(event){
		

		this.rpc.doPost('reports/statussummary', event).subscribe((data: any)=>{
			
			this.statussumtotal = data.result.total;
			this.statussumdata = data.result.records;

    	});

  } 

  	doAmountSummary(event){
		

		this.rpc.doPost('reports/amountsummary', event).subscribe((data: any)=>{
			
			this.amountsumtotal = data.result.total;
			this.amountsumdata = data.result.records;

    	});

  } 

    doAmountSummaryDetail(event){
    

    this.rpc.doPost('reports/amountsummarydetail', event).subscribe((data: any)=>{
      
      this.amountsumdetailtotal = data.result.total;
      this.amountsumdetaildata = data.result.records;

      });

  } 

  doDetail($typ, $sub){
  setTimeout(()=>{
    this.router.navigate(['/reports/detail/' + $typ + '/' + $sub]);
  }, 50);
  }

  doClients(){
  setTimeout(()=>{
    this.router.navigate(['/clientsearch']);
  }, 50);
  }  

  doLeads(){
  setTimeout(()=>{
    this.router.navigate(['/newleads']);
  }, 50);
  }

  doBack(){
  setTimeout(()=>{
      this._location.back();
  }, 50);
  }


}
