import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({ 
  providedIn: 'root'
})
export class RpcService {

	serverUrl: String = "";
	token: String = "";
	
	constructor(private http: HttpClient) {

		this.serverUrl = 'https://cms.easy2pay.co.uk';
		this.serverUrl = 'https://test.easy2pay.co.uk';
		//this.serverUrl = 'http://localhost:4200';

	}


	hasToken(){ return sessionStorage.hasProperty('authToken'); }

  	setToken($token){ sessionStorage.setItem('authToken', $token); }

	clearToken(){ sessionStorage.removeItem('authToken'); }	

	doPost(api, params){

		return this.http.post(this.buildUrl(api), JSON.stringify(params), {headers: this.buildHeader()});

  	}

	doGet(api){

		return this.http.get(this.buildUrl(api), { headers: this.buildHeader()});
  	}

	buildUrl(api){ return `${environment.apiUrl}` + '/' + api + "?ts=" + Date.now() + Math.random(); }

	buildHeader(){

		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		if(sessionStorage.hasOwnProperty('authToken')){
			headers =  headers.set('auth-token', sessionStorage.getItem('authToken'))
		}

		return headers;
	}	


}
