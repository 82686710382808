import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';
import {Subject} from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';



@Directive({
    selector: '[ngModel][debounce]',
})
export class DebounceDirective {

    private isFirstChange: boolean = true;

    @Output() skipDebounceChange = new EventEmitter();

    @Output()
    public onDebounce = new EventEmitter<any>();

    @Input('debounce')
    public debounceTime: number = 500;

    @Input('skipDebounce')
    get skipDebounce(){
        return this.isFirstChange;
    }
    set skipDebounce(val: boolean){
        if(this.isFirstChange !== val){
            this.isFirstChange = val;
            this.skipDebounceChange.emit(this.isFirstChange);
        }
    }

    constructor(public model: NgControl) {
    }

    ngOnInit() {
        this.model.valueChanges
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(modelValue => {
                  if (this.skipDebounce) {
                    this.skipDebounce = false;
                  } else {
                    this.onDebounce.emit(modelValue);
                  }
            });
    }
}