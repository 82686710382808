import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import {MenuItem} from 'primeng/api';


@Component({
  selector: 'app-searchtoolbar',
  templateUrl: './searchtoolbar.component.html',
  styleUrls: ['./searchtoolbar.component.css']
})
export class SearchtoolbarComponent implements OnInit {

  mbitems: MenuItem[];

  constructor() { }

  ngOnInit(): void {
  }

}
