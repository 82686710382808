<p-menubar [model]="mbitems">
        <p-splitButton *ngIf="currentstatus == 'CHASING PAYMENT' || currentstatus == 'CHASING CARDS'" label="Chasing Payment Txts" icon="pi pi-plus" [model]="sbcptxtitems" styleClass="p-button-warning"></p-splitButton>&nbsp;
        <button pButton type="button" label="Book In" icon="pi pi-plus" (click)="doAddBooking()"></button>&nbsp;
        <button pButton type="button" label="Book Fitting" icon="pi pi-plus" (click)="doAddFitting()"></button>&nbsp;
        <button pButton type="button" label="Item" icon="pi pi-plus" (click)="doAddJob('CARPET')"></button>&nbsp;
        <button pButton type="button" label="Upload Document" icon="pi pi-plus" (click)="documentsform = true"></button>&nbsp;
        <button pButton [disabled]="lockpay" type="button" label="Take Payment" icon="pi pi-money-bill" (click)="doAddPayment()"></button>&nbsp;
        <button pButton [disabled]="lockpay" type="button" label="Repeat Payment" icon="pi pi-money-bill" (click)="doRepeatPayment()"></button>&nbsp;
  <button pButton type="button" label="Back" icon="pi pi-trash" (click)="doBack()"></button>&nbsp;
</p-menubar>
<br>
<div class="p-grid p-nogutter">

    <div class="p-col-8 p-nogutter">

        <div class="p-grid p-nogutter">

            <div class="p-col-8 p-nogutter">
    			<p-panel>
    			    <p-header>
    			        Details
    			    </p-header>

    		    	<div class="p-grid p-nogutter">
       					<div class="p-col-4"><label class="lbl-right">Name:</label></div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="clientname" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       					<div class="p-col-4"><label class="lbl-right">Address:</label></div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address1" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       					<div class="p-col-4">&nbsp;</div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address2" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       					<div class="p-col-4">&nbsp;</div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address3" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       					<div class="p-col-4">&nbsp;</div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address4" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       					<div class="p-col-4">&nbsp;</div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="address5" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       					<div class="p-col-4"><label class="lbl-right">Post Code:</label></div>
       					<div class="p-col-4"><input type="text" pInputText [(ngModel)]="postcode" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
                        <div class="p-col-4"></div>
       					<div class="p-col-4"><label class="lbl-right">Phone:</label></div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="phone" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       					<div class="p-col-4"><label class="lbl-right">Mobile:</label></div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="mobile" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
       					<div class="p-col-4"><label class="lbl-right">E-mail:</label></div>
       					<div class="p-col-8"><input type="text" pInputText [(ngModel)]="email" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
                        <div class="p-col-4"><label class="lbl-right">Facebook:</label></div>
                        <div class="p-col-8"><input type="text" pInputText [(ngModel)]="facebook" class="inp-left" [debounce]="400" (onDebounce)="doSaveClient()"></div>
        			</div>

    			</p-panel>        	
            </div>
            
            <div class="p-col-4 p-nogutter">
            	<p-panel>
    		    	<p-header>
    		        	Summary
    		    	</p-header>

    		    	<div class="p-grid p-nogutter">
       					<div class="p-col-6"><label class="lbl-right">Total:</label></div>
       					<div class="p-col-6"><input type="text" pInputText [(ngModel)]="summarytotal" readonly="readonly" class="inp-right"></div>
       					<div class="p-col-6"><label class="lbl-right">Balance:</label></div>
       					<div class="p-col-6"><input type="text" pInputText [(ngModel)]="summarybalance" readonly="readonly" class="inp-right"></div>
       					<div class="p-col-6"><label class="lbl-right">Missed Payment:</label></div>
       					<div class="p-col-6"><input type="text" pInputText [(ngModel)]="summarymissed" readonly="readonly" class="inp-mid"></div>
       					<div class="p-col-6"><label class="lbl-right">Status:</label></div>
       					<div class="p-col-6"><p-dropdown  [options]="statuses" [(ngModel)]="currentstatus" appendTo="body" class="inp-mid" [debounce]="400" (onDebounce)="doSaveStatus()" [style]="{'width':'100%'}"></p-dropdown></div>
    				</div>
    			</p-panel>

            	<p-panel>
    		    	<p-header>
    		        	Traffic Light
    		    	</p-header>

    		    	<div class="p-grid p-nogutter">
       					<div class="p-col-6"><label class="lbl-right">Received:</label></div>
       					<div class="p-col-6"><p-checkbox [(ngModel)]="flg_received" binary="true" (click)="doSaveFlags();"></p-checkbox></div>
       					<div class="p-col-6"><label class="lbl-right">Fault:</label></div>
       					<div class="p-col-6"><p-checkbox [(ngModel)]="flg_fault" binary="true" (click)="doSaveFlags();"></p-checkbox></div>
    				</div>
    			</p-panel>


                <p-panel>
                    <p-header>
                        Payments <span *ngIf="paymentgateway == 'YP'">- Yorkshire Payments</span><span *ngIf="paymentgateway == 'TP'">- Take Payments Online</span>
                    </p-header>

                    <div class="p-grid p-nogutter">
                        <div class="p-col-6">
                            <label class="lbl-right">Payment Date:</label>
                        </div>
                        <div class="p-col-6">
                            <p-calendar [inputStyle]="{'width': '80%', 'min-width': '80%'}" [ngModel]="paymentdate" dateFormat="dd/mm/yy" (ngModelChange)="doPaymentCalc($event, 'date')" showIcon="true"></p-calendar>
                        </div>
                        <div class="p-col-6">
                            <label class="lbl-right">Repeat Every:</label>
                        </div>
                        <div class="p-col-6">
                            <p-dropdown [options]="repeatable" [ngModel]="paymentevery" (ngModelChange)="doPaymentCalc($event, 'every')" appendTo="body" [style]="{ 'width': '100%'}"></p-dropdown>
                        </div>
                        <div class="p-col-6">
                            <label class="lbl-right">Amount to Take</label>
                        </div>
                        <div class="p-col-6">
                            <input type="text" pInputText [(ngModel)]="paymentamount" class="inp-right">
                        </div>
                        <div class="p-col-6">
                            <label class="lbl-right">Last Payment</label>
                        </div>
                        <div class="p-col-6">
                            <p-calendar [inputStyle]="{'width': '80%', 'min-width': '80%'}" [(ngModel)]="paymentlastdate" dateFormat="dd/mm/yy" [readonlyInput]="true"></p-calendar>

                        </div>
                        <div class="p-col-12"><button pButton type="button" label="History" (click)="pppayhistory = true;" class="ui-button-secondary"></button></div> 
                        <div class="p-col-12">&nbsp;<br/> {{ payment_calc }}</div>
                    </div>

                </p-panel>

            </div>
		</div>
        
        <div class="p-col-12" *ngFor="let entry of diarys">
            <p-toolbar>
                <div class="ui-toolbar-group-left">
                    <label><strong>DIARY</strong></label>
                    <i>&nbsp;</i>
                    <i class="pi pi-bars"></i>
                    <i>&nbsp;</i>
                    <label><strong>{{ entry.reason }}</strong></label>
                </div>
                

                <div class="ui-toolbar-group-right">
                    <i class="pi pi-bars"></i>
                    <i>&nbsp;</i>
                    <label><strong>{{ entry.scheduled| date:'d/M/yyyy' }}</strong></label>
                    <i>&nbsp;</i>
                    <i class="pi pi-bars"></i>
                    <i>&nbsp;</i>
                    <button pButton type="button" icon="pi pi-check" (click)="doDiaryCompleted(entry.id)" class="ui-button-secondary"></button>
                </div>
            </p-toolbar>

        </div>

        <div class="p-col-12">
            <p-tabView [(activeIndex)]="baseTab">
                <p-tabPanel header="Jobs">            

<p-table #dtjob [value]="Jobs" [columns]="jobcols" [lazy]="true" (onLazyLoad)="doJobSearch($event)" [paginator]="true" 
    [rows]="10" [totalRecords]="TotalJobRecords" selectionMode="single" [(selection)]="SelectedJob" dataKey="id" [responsive]="true" [rowsPerPageOptions]="[10,50,100,250,500]" scrollable="true" scrollHeight="20vh"
    >
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of jobcols" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <tr  [pSelectableRow]="rowData" (dblclick)="doJobEdit($event, rowData)">
            <td *ngFor="let col of jobcols"  [ngSwitch]="col.field" >
                <div *ngSwitchCase="'documents_delivered'">
                    <div *ngIf="rowData.documents_delivered == 2">{{rowData.documents_delivered_date| date:'d/M/yyyy'}}</div>
                    <div *ngIf="rowData.documents_delivered == 1">No Documents</div>
                </div>
                <div *ngSwitchCase="'product_delivered'">
                    <div *ngIf="rowData.product_delivered == 2">{{rowData.product_delivered_date| date:'d/M/yyyy'}}</div>
                    <div *ngIf="rowData.product_delivered == 1">Not Delivered</div>
                </div>
                <div *ngSwitchDefault>{{rowData[col.field]}}</div>
            </td>
        </tr>
    </ng-template>
</p-table>
                </p-tabPanel>

            <p-tabPanel header="Documents">

<p-table #dtjob [value]="Docs" [columns]="doccols" [lazy]="true" (onLazyLoad)="doDocSearch($event)" [paginator]="true" 
    [rows]="10" [totalRecords]="TotalDocRecords" selectionMode="single" [(selection)]="SelectedDoc" dataKey="id" [responsive]="true" [rowsPerPageOptions]="[10,50,100,250,500]" scrollable="true" scrollHeight="20vh"
    >
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of doccols" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <tr  [pSelectableRow]="rowData" (dblclick)="doJobEdit($event, rowData)">
            <td *ngFor="let col of doccols"  [ngSwitch]="col.field" >
                <div *ngSwitchCase="'dt_created'">
                    <div>{{rowData.dt_created| date:'d/M/yyyy   H:i'}}</div>
                </div>
                <div *ngSwitchDefault>{{rowData[col.field]}}</div>
            </td>
        </tr>
    </ng-template>
</p-table>

            </p-tabPanel> 

            <p-tabPanel header="Assigned Stock" [disabled] = "!StockUser">
                <p>Add Button<button pButton type="button" label='Reserve Stock' (click)="doTakePayment()"></button></p>
                <p>Add Table</p>

            </p-tabPanel>
            </p-tabView>

        </div>
    </div>
    <div class="p-col-4">
        <p-tabView [(activeIndex)]="noteTab">
            <p-tabPanel header="Deliverys">
            <p-paginator [first]="noteselecteddel" rows="1" [totalRecords]="notescountdel" (onPageChange)="doSelectNoteDel($event)"></p-paginator>
            <p-panel>
                <p-header>
                    Deliverys Note: {{noteselecteddel}} OF {{ notescountdel }} - {{ notedeldate | date:'d/M/yyyy - h:mm a' }} - {{ notedeluser }}
                </p-header>
                <textarea [rows]="40" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="notedel" [debounce]="750" (onDebounce)="doSaveNote('del')" [(skipDebounce)]="notedelChange"></textarea>
            </p-panel>   
            </p-tabPanel>
            <p-tabPanel header="Accounts">
            <p-paginator [first]="noteselectedacc" rows="1" [totalRecords]="notescountacc" (onPageChange)="doSelectNoteAcc($event)"></p-paginator>
            <p-panel>
                <p-header>
                    Accounts Note: {{noteselectedacc}} OF {{ notescountacc }} - {{ noteaccdate | date:'d/M/yyyy - h:mm a' }} - {{ noteaccuser }}
                </p-header>
                <textarea [rows]="40" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="noteacc" [debounce]="750" (onDebounce)="doSaveNote('acc')" [(skipDebounce)]="noteaccChange"></textarea>
            </p-panel>   
            </p-tabPanel>
            <p-tabPanel header="Other">
            <p-paginator [first]="noteselectedold" rows="1" [totalRecords]="notescountold" (onPageChange)="doSelectNoteOld($event)"></p-paginator>
            <p-panel>
                <p-header>
                    Other Note: {{noteselectedold}} OF {{ notescountold }} - {{ noteolddate | date:'d/M/yyyy - h:mm a' }} - {{ noteolduser }}
                </p-header>
                <textarea [rows]="40" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="noteold" [debounce]="750" (onDebounce)="doSaveNote('old')" [(skipDebounce)]="noteoldChange"></textarea>
            </p-panel>   

        </p-tabPanel>
        </p-tabView>
 	
    </div>
</div>


<!--- DIARY ENTRY FORM --->
<p-dialog [(visible)]="diaryadd" [modal]="true" [draggable] = "false" header="Add Diary Entry"  [style]="{ width: '50vw' }">
    <div class="p-grid p-nogutter">
        <div class="p-col-2">Date</div>
        <div class="p-col-10"><p-calendar [(ngModel)]="diaryschedule" dateFormat="dd/mm/yy" appendTo="body" showIcon="true"></p-calendar></div>
        <div class="p-col-2">Reason</div>
        <div class="p-col-10"><textarea [rows]="10" [cols]="40" pInputTextarea [(ngModel)]="diaryreason"></textarea></div>
    </div>
    <p-footer>
        <button pButton type="button" label='Save' (click)="doSaveNewDiary()"></button>
        <button pButton type="button" label='Cancel' (click)="diaryadd = false;"></button>
    </p-footer>
</p-dialog>

<!--- NOTE ENTRY FORM --->
<p-dialog [(visible)]="ppNoteView" [modal]="true" [closable]="!ppNoteDisabled" [draggable] = "false">
    <p-header>
        {{ ppNoteTitle }}
    </p-header>
    	<textarea [rows]="10" [cols]="80" pInputTextarea [(ngModel)]="ppNote"></textarea> 
	<p-footer>
        <button pButton type="button" label='Save' [disabled]="ppNoteDisabled" (click)="doSaveNote('aa')"></button>
        <button pButton type="button" label='Cancel' [disabled]="ppNoteDisabled" (click)="ppNoteView = false;"></button>
    </p-footer>
</p-dialog>


<!--- TAKE PAYMENT FORM --->
<p-dialog header="Take Payment - Take Payments Online" [(visible)]="ppAddPaymentView" [modal]="true" [closable]="!ppAddPaymentDisabled" [draggable] = "false" [style]="{ width: '40vw' }">
        
    <div class="p-grid">
        <div class="p-col-6"><label class="lbl-right">Payment Type</label></div>
        <div class="p-col-6"><p-dropdown  [options]="paymentrepeatdeposititems" [(ngModel)]="paymentrepeatdeposit" appendTo="body"></p-dropdown></div>
        <div *ngIf="paymentrepeatdeposit" class="p-col-6"><label class="lbl-right">Select Item</label></div>
        <div *ngIf="paymentrepeatdeposit" class="p-col-6"><p-dropdown [options]="jobspay" [(ngModel)]="tpjob" appendTo="body"></p-dropdown></div>
        <div class="p-col-6"><label class="lbl-right">Name on Card:</label></div>
        <div class="p-col-6"><input type="text" pInputText [(ngModel)]="tpname" class="inp-left"></div>
        <div class="p-col-6"><label class="lbl-right">Card Number:</label></div>
        <div class="p-col-6"><input type="text" pInputText [(ngModel)]="tpcard" class="inp-left" required></div>
        <div class="p-col-6"><label class="lbl-right">Expiry:</label></div>
        <div class="p-col-6">
            <p-dropdown  [options]="months" [(ngModel)]="tpmonth" appendTo="body" [style]="{'width':'120px'}"></p-dropdown> / <p-dropdown [options]="years" [(ngModel)]="tpyear" appendTo="body" [style]="{'width':'80px'}"></p-dropdown>
        </div>
        <div class="p-col-6"><label class="lbl-right">Card Verification Number:</label></div>
        <div class="p-col-6"><input type="text" pInputText [(ngModel)]="tpcvv" class="inp-half-left"></div>
        <div *ngIf="paymentrepeatdeposit" class="p-col-6"><label class="lbl-right">Amount:</label></div>
        <div *ngIf="paymentrepeatdeposit" class="p-col-6"><input type="text" pInputText [(ngModel)]="tppay" class="inp-half-right"></div>
    </div>

	<p-footer>
        <button pButton type="button" label='Save' [disabled]="lockpay" (click)="doTakePayment()"></button>
        <button pButton type="button" label='Cancel' [disabled]="ppAddPaymentDisabled" (click)="ppAddPaymentView = false;"></button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="ppAjustPaymentView" [modal]="true" [closable]="!ppAjustPaymenttDisabled" [draggable] = "false"  [style]="{ width: '40vw' }">
    <p-header>
        Payment Ajustment
    </p-header>
        
        <div class="ui-g ui-g-nopad">
            <div class="ui-g-12">
                <div class="ui-g-6 ui-g-nopad"><label class="lbl-right">Amount:</label></div>
                <div class="ui-g-6 ui-g-nopad"><input type="text" pInputText [(ngModel)]="ppAdjustPay" class="inp-half-right"></div>
            </div> 
        </div>
    <p-footer>
        <button pButton type="button" label='Save' [disabled]="ppAjustPaymenttDisabled" (click)="doTakeAdjustment()"></button>
        <button pButton type="button" label='Cancel' [disabled]="ppAjustPaymenttDisabled" (click)="ppAjustPaymentView = false;"></button>
    </p-footer>
</p-dialog>


<!-- JOB ENTRY FORM -->
<p-dialog [(visible)]="ppAddJobView" [modal]="true" [closable]="!ppAddJobDisabled" [draggable] = "false"  [style]="{ width: '40vw' }">
    <p-header>
        {{ ppJobHeader }} {{ ppJobType }}
    </p-header>

        <div class="p-grid">
            <div class="p-col-6"><label class="lbl-right">Type:</label></div>
            <div *ngIf="ppJobEdit" class="p-col-6"><input type="text" pInputText [(ngModel)]="ppJobType"[readonly]="true" class="inp-left"></div>
            <div *ngIf="!ppJobEdit" class="p-col-6"><p-dropdown  [options]="jobtypes" [(ngModel)]="ppJobType" appendTo="body"></p-dropdown></div>

            <div class="p-col-6"><label class="lbl-right">Reference:</label></div>
            <div class="p-col-6"><input type="text" pInputText [(ngModel)]="ppJobReference" class="inp-left"></div>
            <div class="p-col-6"><label class="lbl-right">Total:</label></div>
            <div class="p-col-6"><input type="text" pInputText [ngModel]="ppJobTotal" (ngModelChange)="doJobRepeatCalc($event, 'Total')"class="inp-right"></div>
            <div *ngIf="ppJobDepositTaken" class="p-col-6"><label class="lbl-right">Balance:</label></div>
            <div *ngIf="ppJobDepositTaken" class="p-col-6"><input type="text" pInputText [(ngModel)]="ppJobBalance" class="inp-right" [readonly]="true"></div>
            <div *ngIf="ppJobEdit" class="p-col-6"><label class="lbl-right">Received Documents:</label></div>
            <div *ngIf="ppJobEdit" class="p-col-6"><p-checkbox [(ngModel)]="ppJobDocumentDelivered" binary="true"></p-checkbox><p-calendar  [(ngModel)]="ppJobDocumentDeliveredDate" dateFormat="dd/mm/yy" appendTo="body" showIcon="true" [utc]="false" [disabled]="!ppJobDocumentDelivered"></p-calendar></div>
            <div *ngIf="ppJobEdit" class="p-col-6"><label class="lbl-right">Delievered:</label></div>
            <div *ngIf="ppJobEdit" class="p-col-6"><p-checkbox [(ngModel)]="ppJobProductDelivered" binary="true"></p-checkbox><p-calendar  [(ngModel)]="ppJobProductDeliveredDate" dateFormat="dd/mm/yy" appendTo="body" showIcon="true" [utc]="false" [disabled]="!ppJobProductDelivered"></p-calendar></div>
            <div class="p-col-6"><label class="lbl-right">Deposit Amount:</label></div>
            <div class="p-col-6"><input type="text" pInputText [ngModel]="ppJobDeposit" (ngModelChange)="doJobRepeatCalc($event, 'Deposit')" class="inp-right" [readonly]="ppJobDepositTaken"></div>
            <div class="p-col-6"><label class="lbl-right">Repeat Amount:</label></div>
            <div class="p-col-6"><input type="text" pInputText [ngModel]="ppJobRepeat" (ngModelChange)="doJobRepeatCalc($event, 'RepeatAmount')" class="inp-right"></div>
            <div class="p-col-12">{{ repeatable_calc }}</div>

        </div>
    <p-footer>
        <button pButton type="button" label='Save' [disabled]="ppAddJobDisabled" (click)="doSaveJob(!ppJobEdit)"></button>
        <button pButton type="button" label='Cancel' [disabled]="ppAddJobDisabled" (click)="ppAddJobView = false;"></button>
    </p-footer>
</p-dialog>

<!-- PAYMENT HISTORY -->
<p-dialog [(visible)]="pppayhistory" [modal]="true" [draggable] = "false" [style]="{ width: '80vw' }">
    <p-header>
        PAYMENT HISTORY
    </p-header>

        <div class="p-grid">
            <div class="p-col-12">
<p-table #dtPayHistory [value]="PayHistory" [lazy]="true" (onLazyLoad)="doPayHistorySearch($event)" [paginator]="true" paginatorDropdownAppendTo="body"
    [rows]="10" [totalRecords]="TotalPayHistoryRecords" [responsive]="true" [rowsPerPageOptions]="[10,50,100,250,500]" scrollable="true" scrollHeight="20vh">
    <ng-template pTemplate="header">
        <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Status</th>
            <th>Response</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-Pay>
        <tr>
            <td>{{Pay.paymentdate}}</td>
            <td>{{Pay.amount}}</td>
            <td>{{Pay.paymenttype}}</td>
            <td>{{Pay.status}}</td>
            <td>{{Pay.gatewayresponse}}</td>
        </tr>
    </ng-template>
</p-table>
            </div>
        </div>
    <p-footer>
        <button pButton type="button" label='Close' [disabled]="ppAddJobDisabled" (click)="pppayhistory = false;"></button>
    </p-footer>
</p-dialog>



<!-- REP BOOKING ENTRY FORM -->
<p-dialog [(visible)]="bookingform" [modal]="true" [closable]="false" [draggable] = "false">
    <p-header>
        Rep Booking
    </p-header>
      <div class="p-grid nogutter">
          <div class="p-col-4">Date:</div>
          <div class="p-col-8"><p-calendar [(ngModel)]="bookingdate" dateFormat="dd/mm/yy" appendTo="body" showIcon="true"></p-calendar></div>
          <div class="p-col-4">Rep:</div>
          <div class="p-col-8"> <p-dropdown [options]="reps" [(ngModel)]="bookrep" appendTo="body" [style]="{'width': '100%'}"></p-dropdown></div>
          <div class="p-col-4">Interested In:</div>
          <div class="p-col-8"><input type="text" pInputText [(ngModel)]="bookinterested" style="width: 100%;"></div>
          <div class="p-col-4"><label>Note:</label></div>
          <div class="p-col-8">
            <textarea [rows]="10" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="bookingnote"></textarea>
          </div>
      </div>
  <p-footer>
        <button pButton type="button" label='Book' (click)="doSaveBooking()"></button>
        <button pButton type="button" label='Cancel' (click)="bookingform = false;"></button>
    </p-footer>
</p-dialog>

<!-- FITTING ENTRY FORM -->
<p-dialog [(visible)]="fittingform" [modal]="true" [closable]="false" [draggable] = "false">
    <p-header>
        Fitting Booking
    </p-header>
      <div class="p-grid nogutter">
          <div class="p-col-4">Date:</div>
          <div class="p-col-8"><p-calendar [(ngModel)]="fittingdate" dateFormat="dd/mm/yy" appendTo="body" showIcon="true"></p-calendar></div>
          <div class="p-col-4">Fitting:</div>
          <div class="p-col-8"> <p-dropdown [options]="fits" [(ngModel)]="fittingrep" appendTo="body" [style]="{'width': '100%'}"></p-dropdown></div>
          <div class="p-col-4"><label>Note:</label></div>
          <div class="p-col-8">
            <textarea [rows]="10" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="fittingnote"></textarea>
          </div>
      </div>
  <p-footer>
        <button pButton type="button" label='Book' (click)="doSaveFitting()"></button>
        <button pButton type="button" label='Cancel' (click)="fittingform = false;"></button>
    </p-footer>
</p-dialog>

<!-- DOCUMENT UPLOAD FORM -->
<p-dialog [(visible)]="documentsform" [modal]="true" [closable]="false" [draggable] = "false">
    <p-header>
        Upload Document
    </p-header>
      <div class="p-grid nogutter">
          <div class="p-col-4">Document Type:</div>
          <div class="p-col-8"> <p-dropdown [options]="fits" [(ngModel)]="fittingrep" appendTo="body" [style]="{'width': '100%'}"></p-dropdown></div>
      </div>
  <p-footer>
        <p-fileUpload #fubauto mode="basic" name="demo[]" url="./upload.php" accept="image/*" maxFileSize="1000000" (onUpload)="onBasicUploadAuto($event)" [auto]="true" chooseLabel="Browse" uploadHandler="onCustomUpload()" [customUpload]="true"></p-fileUpload>
        <button pButton type="button" label='Cancel' (click)="documentsform = false;"></button>
    </p-footer>
</p-dialog>