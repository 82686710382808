import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CacheService } from './cache.service'
import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private cache: CacheService,
        private router: Router,
        private http: HttpClient
    ) {

        this.userSubject = new BehaviorSubject<User>(this.cache.fetch('auth', null));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username: string, password: string) {
        let data = { user: username, pass: password }
        return this.http.post<any>(`${environment.apiUrl}/auth/login`, data)
            .pipe(map(user => {
                this.cache.store("auth", user.result);
                this.userSubject.next(user.result);
                this.startRefreshTokenTimer();
                return user.result;
            }));
    }

    logout() {
        this.stopRefreshTokenTimer();
        this.cache.remove("auth");
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    refreshToken() {
        return this.http.post<any>(`${environment.apiUrl}/auth/refresh`, {})
            .pipe(map((user) => {
                this.cache.store("auth", user.result);
                this.userSubject.next(user.result);
                this.startRefreshTokenTimer();
                return user.result;
            }));
    }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        if(this.userSubject.value !== null){

        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.userValue.jwttoken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
        }
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
