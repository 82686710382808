import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService, CacheService } from '../../_services';

@Component({
  selector: 'app-newleadsearch',
  templateUrl: './newleadsearch.component.html',
  styleUrls: ['./newleadsearch.component.css']
})
export class NewleadsearchComponent implements OnInit {


  @ViewChild('dt') mytable: any;

	cols: any[];
	loading: boolean;

  Clients: any;
  SelectedClient: any;
  TotalClientRecords: number;
  ClientSearchEvent: any;

  CallFlag: number = 0;

  currentuser: string;
  currentuserid: string;
  RepView: boolean = true;

  cb1: boolean =  true;
  cb2: boolean = true;
  cb3: boolean = true;
  cb4: boolean = true;

  canscroll: boolean = false;


  constructor(private sysmsg: MessageService, private rpc: RpcService, private router: Router,    private authenticationService: AuthenticationService, private cache: CacheService) {


      this.currentuser = this.authenticationService.userValue.username; 
      this.currentuserid = this.authenticationService.userValue.user; 

    this.RepView = false;
    this.CallFlag = this.cache.fetch('leads-searchflag',0);
    this.doAjustCallBtns(this.CallFlag);

  }

  doAjustCallBtns(flg){

    this.cb1 = (flg == 0) ? true : false;
    this.cb2 = (flg == 1) ? true : false;
    this.cb3 = (flg == 2) ? true : false;
    this.cb4 = (flg == 8) ? true : false;

  }

  ngOnInit() {

//    if(['hmb','cb','dk','df','developer'].indexOf(this.authenticationService.userValue.user) >= 0){
      this.RepView = true;
 //   } else {
  //    this.RepView = false;
  //  }


  	this.cols = [
  		{ field: 'clientname', header: 'Name' },
      { field: 'phone', header: 'Phone' },
  		{ field: 'address1', header: 'Address' },
  		{ field: 'postcode', header: 'Post Code' },
      { field: 'besttime', header: 'Best Time' },
  		{ field: 'interested', header: 'Interested' },
  	];


  }

  doClientSearch(event){
  	this.ClientSearchEvent = event;
    event['CallFlag'] = this.CallFlag;

    this.rpc.doPost('newleads/search', event).subscribe((data: any)=>{
      this.TotalClientRecords = data.result.total;
      this.Clients = data.result.records;

      if(data.records.length > 10){
        this.canscroll = true;
      } else {
        this.canscroll = false;
      }      

    });

  }

 onRowDblClick($event, rowData) {
	setTimeout(()=>{
		this.router.navigate(['/lead/' + rowData.id]);
	}, 50);
    }  

  doClients(){
  setTimeout(()=>{
    this.router.navigate(['/clientsearch']);
  }, 50);
  }

  doReports(){
  setTimeout(()=>{
    this.router.navigate(['/reports/summary']);
  }, 50);
  }

  doBookings(){
  setTimeout(()=>{
    this.router.navigate(['/bookings/list']);
  }, 50);
  }


  doCall(c){
    this.CallFlag = c;
    this.cache.store('leads-searchflag',c);
    this.doAjustCallBtns(c);
    this.doClientSearch(this.ClientSearchEvent)

  }

  doDiary(){
  setTimeout(()=>{
    this.router.navigate(['/diary/' + this.authenticationService.userValue.user]);
  }, 50);
  }

  doLogout(){ this.authenticationService.logout(); }

  doReset(){
     this.mytable.clear();
     this.mytable.clearState();
  }

}
