<p-menubar>
    <button pButton type="button" label="Clients" (click)="doClients()"></button>&nbsp;
    <button pButton type="button" label="New Leads" (click)="doLeads()"></button>&nbsp;
    <button pButton type="button" label="Back" icon="pi pi-trash" (click)="doBack()"></button>&nbsp;    
</p-menubar>
<p-table #dt [value]="ListData" [columns]="cols" sortField="repeat_days" [lazy]="true" (onLazyLoad)="doListing($event)" [paginator]="true" 
    [rows]="100" [totalRecords]="TotalListRecords" [loading]="loading" selectionMode="single" [(selection)]="SelectedClient" dataKey="clientid" [responsive]="true" [rowsPerPageOptions]="[100,250,500]">
    <ng-template pTemplate="header">
        <tr>
			<th style="width:40px;"></th>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
			<th style="width:20px;"></th>
        </tr>
        <tr>
			<th style="width:40px;"></th>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
                <input *ngSwitchCase="'clientid'" pInputText type="text" style="width: 100% !important;" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" [value]="dt.filters[col.field]?.value">
               <input *ngSwitchCase="'clientname'" pInputText type="text"  style="width: 100% !important;" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value" />
               <input *ngSwitchCase="'address'" pInputText type="text"  style="width: 100% !important;" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
               <input *ngSwitchCase="'postcode'" pInputText type="text"  style="width: 100% !important;" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"[value]="dt.filters[col.field]?.value">
            </th>
			<th style="width:20px;"></th>
        </tr>        
    </ng-template>
    <ng-template pTemplate="body" let-rowData  let-i="rowIndex">
        <tr  [pSelectableRow]="rowData" (dblclick)="doLoadClient($event, rowData)">
			<td style="width:40px;">{{i+1}}</td>
            <td *ngFor="let col of cols">
                    {{rowData[col.field]}}
            </td>
            <td style="width:20px;" [style.background-color]="doGetTraffic(rowData['flg_traffic'])"></td>
        </tr>
    </ng-template>
</p-table>
