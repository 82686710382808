import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-newleadsedit',
  templateUrl: './newleadsedit.component.html',
  styleUrls: ['./newleadsedit.component.css']
})
export class NewleadseditComponent implements OnInit {

	loading: boolean = false;

	id: number;

	subroute: any;

  // client details
  clientname: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  postcode: string;
  phone: string;
  email: string;

  dnote: string;

  interested: string;
  heardfrom: string;
  wherefrom: string;
  promo: string;
  besttime: string;

  // Call Flags
  callflag: number = 0;
  cbone: boolean = true;
  cb2: boolean = true;
  cb3: boolean = true;

  // Book Rep Form
  bookingform: boolean = false;
  bookingdate: Date;
  reps: any[];
  bookrep: string;
  bookinterested: string;
  bookingnote: string;
  
  // Callback Form
  callbackform: boolean = false;
  callbackdate: Date;

  constructor(private sysmessage: MessageService, private rpc: RpcService, private router: Router, private routeparams: ActivatedRoute, private _location: Location ) { }

  ngOnInit() {


    this.subroute = this.routeparams.params.subscribe(params => {
       this.id = +params['id']; // (+) converts string 'id' to a number
       //this.cacheid = 'cv' + this.id;
       //this.JobSearchTable = 'jb' + this.id;
       // Trigers Loading
       this.doLoad();

    });

    this.rpc.doGet('lookup/reps').subscribe((data: any)=>{
    	this.reps = data.result;
    });     

  }

  notify(typ, summ, det){
    this.sysmessage.add({severity:typ, summary: summ, detail:det});
  }


  doLoad(){

	this.loading = true;

    	this.rpc.doGet('newleads/fetch/' + this.id).subscribe((data: any)=>{
    		// Do Setup

        this.clientname = data.result.data.clientname;
        this.address1 = data.result.data.address1;
        this.address2 = data.result.data.address2;
        this.address3 = data.result.data.address3;
        this.address4 = data.result.data.address4;
        this.address5 = data.result.data.address5;
        this.postcode = data.result.data.postcode;
        this.phone = data.result.data.phone;
        this.email = data.result.data.email;

        this.besttime = data.result.data.besttime;
        this.interested = data.result.data.interested;
        this.heardfrom = data.result.data.heardus;
        this.wherefrom = data.result.data.wherefrom;
        this.promo = data.result.data.promo;

        if(data.result.data.note == null){
          this.dnote = "";
        } else {
          this.dnote = atob(data.result.data.note);
        }

        this.doAjustCallBtns(data.result.data.flg_called);

		setTimeout(()=>{ this.loading = false; }, 3000);
    });
  }

  doBack(){
  setTimeout(()=>{
    this._location.back();
    //this.router.navigate(['/newleads']);
  }, 50);
  }

  doSaveClient(){

    if(this.loading){ return; }

    let data = {
    	id: this.id,
        clientname: this.clientname,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        address4: this.address4,
        address5: this.address5,
        postcode: this.postcode,
        phone: this.phone,
        email: this.email
    }

    this.rpc.doPost('newleads/save', data).subscribe((data: any)=>{

      this.notify('success','New Leads',data.result.msg);

    console.log(data);

    }, (error)=>{
      this.notify('error','New Leads Update',error);
    });      

  }

  doSaveNote(){

    if(this.loading){ return; }

    let data = { id: this.id, note: btoa(this.dnote) };

    this.rpc.doPost('newleads/savenote', data).subscribe((data: any)=>{

      this.notify('success','New Leads',data.result.msg);

    console.log(data);

    }, (error)=>{
      this.notify('error','New Leads Update',error);
    });      

  }

  toSQLDate(dt: Date){ 
    return dt.getFullYear() + '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2) + ' ' + String("00" + dt.getHours()).slice(-2) + ':' + String("00" + dt.getMinutes()).slice(-2) + ':' + String("00" + dt.getSeconds()).slice(-2);
  }

  doAddBooking(){
  	// Sets up the form
    this.bookingdate = new Date(Date.now());
    this.bookingdate.setDate( this.bookingdate.getDate() + 7);
    this.bookrep = null;

    this.bookinterested = this.interested;
    this.bookingnote = "";
  	this.bookingform = true;
  }

  doSaveBooking(){
  	let data = { id: this.id, rep: this.bookrep, interested: this.bookinterested, bookon: this.toSQLDate(this.bookingdate), note: btoa(this.bookingnote), flag: 3};
  	console.log(data);

   this.rpc.doPost('newleads/book', data).subscribe((data: any)=>{

      this.bookingform = false;
      this.notify('success','New Booking',data.result.msg);

    }, (error)=>{
      this.notify('error','New Leads Update',error);
    });
  	
  }

  doSaveFlags(flg){
  
  let data = { id: this.id, flag: flg };

  this.rpc.doPost('newleads/saveflags', data).subscribe((data: any)=>{

      this.notify('success','New Leads',data.result.msg);

    }, (error)=>{
      this.notify('error','New Leads Update',error);
    });      

  
  }

  doAjustCallBtns(flg){
  	console.log(flg);

  	this.cbone = (flg < 1) ? false : true;
  	this.cb2 = (flg == 1) ? false : true;
  	this.cb3 = (flg == 2) ? false : true;

  }

  doSaveCalls(flg){
  
  let data = { id: this.id, flag: flg };
  

  this.rpc.doPost('newleads/savecalls', data).subscribe((data: any)=>{

     this.notify('success','New Leads',data.result.msg);

 		this.doAjustCallBtns(data.result.callflag);
    
        this.dnote = this.dnote + "\r\n" + (new Date()).toLocaleDateString() + " " + data.result.sms;

      if(data.result.sms == "Message Sent!"){
        this.notify('success','New Leads - SMS',data.result.sms);
      } else {
        this.notify('error','New Leads - SMS',data.result.sms);
      }

   }, (error)=>{
     this.notify('error','New Leads Update',error);
   });      

  
  }
  
  doAddCallBack(){ 

    this.callbackdate = new Date(Date.now());
    this.callbackdate.setDate( this.callbackdate.getDate() + 7);
    this.callbackform = true;
  }
  
  doSaveCallBack(){ 

  let data = { id: this.id, flag: 8, dtcb: this.toSQLDate(this.callbackdate) };
  

  this.rpc.doPost('newleads/savecalls', data).subscribe((data: any)=>{

    this.callbackform = false;
      this.notify('success','New Leads',data.result.msg);

      this.doAjustCallBtns(data.result.callflag);
    
        this.dnote = this.dnote + "\r\n" + (new Date()).toLocaleDateString() + " CALLBACK REQUESTED ON" + this.callbackdate.toLocaleDateString();
      this.callbackform = false;


   }, (error)=>{
     this.notify('error','New Leads Update',error);
   });      

  }

}
