import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-repsearch',
  templateUrl: './repsearch.component.html',
  styleUrls: ['./repsearch.component.css']
})
export class RepsearchComponent implements OnInit {

  Clients: any;
  SelectedClient: any;
  TotalClientRecords: number;
  rowGroupMetadata: any;


  constructor(private sysmsg: MessageService, private rpc: RpcService, private router: Router, private routeparams: ActivatedRoute,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  
    this.doLoad();
  }

    updateRowGroupMetaData() {
        this.rowGroupMetadata = {};

        if (this.Clients) {
            for (let i = 0; i < this.Clients.length; i++) {
                let rowData = this.Clients[i];
                let representativeName = rowData.dt_due;
                
                if (i == 0) {
                    this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
                }
                else {
                    let previousRowData = this.Clients[i - 1];
                    let previousRowGroup = previousRowData.dt_due;
                    if (representativeName === previousRowGroup)
                        this.rowGroupMetadata[representativeName].size++;
                    else
                        this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
                }
            }
        }
    }

  doLoad(){
    let dat = { id: 'none' }

    this.rpc.doPost('reps/search', dat).subscribe((data: any)=>{

      this.TotalClientRecords = data.result.recordcount;
      this.Clients = data.result.records;
      this.updateRowGroupMetaData();
    });

  }

    onRowDblClick($event, rowData) {
  setTimeout(()=>{
    this.router.navigate(['/repview/' + rowData.id]);
  }, 50);
    }  

}
