import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  pass: string = "";
  user: string = "";

  loading: boolean = false;

  returnUrl: string;

  constructor(
    private sysmsg: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) {

  	//this.loading = false;
        if (this.authenticationService.userValue) { 
          let usr = this.authenticationService.userValue;
          this.router.navigate([usr.level]);
        }
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login(): void {

  		if((this.user == undefined) || (this.pass == undefined) || (this.user == '') || (this.pass == '')){
	  		this.sysmsg.add({severity:'warn', summary: 'Invalid Details', detail:'Username and password are required!'});
		} else {

			this.loading = true;

			this.authenticationService.login( this.user, this.pass)
            .pipe(first())
            .subscribe({
                next: () => {
                    let usr = this.authenticationService.userValue;
                    this.router.navigate([usr.level]);
                },
                error: error => {
                	this.sysmsg.add({severity:'error', summary: 'Login Error', detail:error});
                	console.log(error);
                    this.loading = false;
                }
            });


	  			//this.sysmsg.add({severity:'error', summary: 'Login Error', detail:err.message});

		}

  }

}
