import { Component, OnInit } from '@angular/core';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';

@Component({
  selector: 'app-stocksmanager',
  templateUrl: './stocksmanager.component.html',
  styleUrls: ['./stocksmanager.component.css']
})
export class StocksmanagerComponent implements OnInit {

  // Lookups
  product_types: any[] = [];
  product_suppliers: any[] = [];
  product_suppliers_filtered: any[] = [];

  product_products: any[] = [{value: null, label: "SELECT PRODUCT"}];

  selectedValue = null;
  listOfOption: Array<{ value: string; text: string }> = [];
  nzFilterOption = (): boolean => true;

  // Table Setup
  StockCols: any[] = [];

  // Table Records
  Stocks: any; // Records
  SelectedOrder: any; // Selected Record
  TotalStockRecords: number; // Number of Records
  StockSearchEvent: any; // Search Record Filter


  // Order Form
  showorderform: boolean = false;
  record_uuid: string;
  stock_supplier: string;
  stock_product: string;
  order_date: Date = new Date();
  arrive_date: Date = new Date();
  order_amount: number = 0;
  isedit: boolean = false;

  // Edit Form
  showeditform: boolean = false;
  showorderformedit: boolean = false;

  doStockSearch(event){

    this.StockSearchEvent = event;

    this.rpc.doPost('orders/list', event).subscribe((data: any)=>{
      this.TotalStockRecords = data.result.total;
      this.Stocks = data.result.records;

    }, (error)=>{
      this.Stocks = [];
      this.TotalStockRecords = 0;
      this.sysmessage.add({severity:'error', summary: 'Order List', detail:'No orders could not be fetched'});
    });


  }

  constructor( private sysmessage: MessageService, private rpc: RpcService ) { }

  ngOnInit(): void {

    this.StockCols = [
      { field: 'supplier_name', header: 'Supplier' },
      { field: 'productname', header: 'Product' },
      { field: 'ordered_on', header: 'Ordered' },
      { field: 'amount', header: 'Amount Ordered' },
      { field: 'completed', header: 'Order Completed' },
    ]

    this.rpc.doGet('products/typelookups').subscribe((data: any)=>{ this.product_types = data.result; });
    this.rpc.doGet('suppliers/lookups').subscribe((data: any)=>{ this.product_suppliers = data.result; });

  }

  toDate(dt: string){
    if (dt !== undefined && dt !== null) {
      let t = dt.split(/[- :]/);
      let d = new Date(Date.UTC(parseInt(t[0]), parseInt(t[1])-1, parseInt(t[2]), parseInt(t[3]), parseInt(t[4]), parseInt(t[5])));
      return d;
    }
    return null;
  }

  toSQLDate(dt: Date){ 
    return dt.getFullYear() + '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2) + ' ' + String("00" + dt.getHours()).slice(-2) + ':' + String("00" + dt.getMinutes()).slice(-2) + ':' + String("00" + dt.getSeconds()).slice(-2);
  }

  doSupplierSearch(event){

  }

  doProductSearch(event){

    this.stock_supplier = event;

    // Request products List from server using supplier uuid
    let dat = { supplier_uuid: this.stock_supplier }
    this.rpc.doPost('products/bysupplier', dat).subscribe((data: any)=>{
      this.product_products = data.result;
    });
  }

  test(): void {
    console.log(this.stock_product);
  }

  //doSupplierSearch(event){
  //  console.log(event);
  //      //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
  //      let filtered : any[] = [];
  //      let query = event.query;

  //      for(let i = 0; i < this.product_suppliers.length; i++) {
  //          let country = this.product_suppliers[i];
  //          if (country.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //              filtered.push(country);
  //          }
  //      }

  //      this.product_suppliers_filtered = filtered;    
  //}


  doNewOrder(txt){
    this.stock_supplier = null;
    this.stock_product = null;
    this.order_date = new Date();
    this.order_amount = 0;

    this.isedit = false;
    this.showorderform = true;
  }

  doEditOrder(event, rowData){

    console.log(rowData);

    let dat = { supplier_uuid: rowData['suppliersuuid']}
    this.rpc.doPost('products/bysupplier', dat).subscribe((data: any)=>{
      this.product_products = data.result;
    this.record_uuid = rowData['uuid'];
    this.stock_supplier = rowData['suppliersuuid'];
    this.stock_product = rowData['productuuid'];
    this.order_date = this.toDate(rowData['ordered_on']);
    this.order_amount = rowData['amount'];
    this.arrive_date = new Date();

    this.isedit = true;
    this.showorderformedit = true;


    });


  }


  doSave(){
    // Collect Information
    let dat = { uuid: this.record_uuid,  productuuid: this.stock_product, suppliersuuid: this.stock_supplier, ordered_on: this.toSQLDate(this.order_date), amount: this.order_amount }

    this.rpc.doPost('orders/save', dat).subscribe((data: any)=>{

       this.sysmessage.add({severity:'info', summary: 'Supplier Details', detail:' Supplier details have been saved'});

       this.showorderform = false;
      this.showorderformedit = false;

       // this.doSupplierSearch(this.SupplierSearchEvent);

    }, (error)=>{
       this.sysmessage.add({severity:'error', summary: 'Supplier Details', detail:' Supplier details have not been saved'});
    });      

  }

  doSaveEdit(){
    // Collect Information
    let dat = { uuid: this.record_uuid,  productuuid: this.stock_product, suppliersuuid: this.stock_supplier, ordered_on: this.toSQLDate(this.order_date), amount: this.order_amount, arrived_on: this.toSQLDate(this.arrive_date), completed: 1 }

    this.rpc.doPost('orders/save', dat).subscribe((data: any)=>{

       this.sysmessage.add({severity:'info', summary: 'Supplier Details', detail:' Supplier details have been saved'});

      this.showorderformedit = false;

       // this.doSupplierSearch(this.SupplierSearchEvent);

    }, (error)=>{
       this.sysmessage.add({severity:'error', summary: 'Supplier Details', detail:' Supplier details have not been saved'});
    });      

  }

}
