import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-clientsearch',
  templateUrl: './clientsearch.component.html',
  styleUrls: ['./clientsearch.component.css']
})
export class ClientsearchComponent implements OnInit {

  @ViewChild('dt') mytable: any;

  currentuser: string;
  RepView: boolean = false;
  canscroll: boolean = false;

  TransactionUser: boolean = false;
  StockUser: boolean = false;

  ppAddClientView: boolean;
  ppAddClientDisabled: boolean;
  clientname: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  postcode: string;
  phone:string;
  mobile:string;
  email:string;


  cols: any[];
  loading: boolean; 

  statuses: any[];
  currentstatus: string;
  yesno: any[];
  currentyesno: string;

  Clients: any;
  SelectedClient: any;
  TotalClientRecords: number;
  ClientSearchEvent: any;

  constructor(private sysmsg: MessageService, private rpc: RpcService, private router: Router,
    private authenticationService: AuthenticationService) {



  }

  ngOnInit() {

    this.currentuser = this.authenticationService.userValue.username;

//    if(['hmb','cb','dk','df','developer'].indexOf(this.authenticationService.userValue.user) >= 0){
      this.RepView = true;
 //   } else {
 //     this.RepView = false;
 //   }

    if(['dk','developer'].indexOf(this.authenticationService.userValue.user) >= 0){
      this.TransactionUser = true;
    }

    if(['cb','dk','developer', 'jm'].indexOf(this.authenticationService.userValue.user) >= 0){
      this.StockUser = true;
    }


    this.cols = [
      { field: 'clientid', header: 'Client ID', filterMatchMode: 'contains' },
      { field: 'clientname', header: 'Name' },
      { field: 'phone', header: 'Phone' },
      { field: 'address', header: 'Address' },
      { field: 'postcode', header: 'Post Code' },
      { field: 'total', header: 'Total' },
      { field: 'balance', header: 'Balance' },
      { field: 'repeat_days', header: 'Day' },
    { field: 'status', header: 'Status' },
    ];

    this.yesno = [];
    this.yesno.push({label: 'All', value: null});
    this.yesno.push({label: 'Yes', value: 'YES'});
    this.yesno.push({label: 'No', value: 'NO'});

  this.statuses = [];
  this.statuses.push({label: 'All', value: null});
  this.statuses.push({label: 'MISCELLANEOUS', value: 'MISCELLANEOUS'});
  this.statuses.push({label: 'CANCELLED', value: 'CANCELLED'});
  this.statuses.push({label: 'ON HOLD', value: 'ON HOLD'});
  this.statuses.push({label: 'PAYING', value: 'PAYING'});
  this.statuses.push({label: 'CHASING PAYMENT', value: 'CHASING PAYMENT'});
  this.statuses.push({label: 'CHASING CARDS', value: 'CHASING CARDS'});
  this.statuses.push({label: 'PAID IN FULL', value: 'PAID IN FULL'});
  this.statuses.push({label: 'DEBT RECOVERY', value: 'DEBT RECOVERY'});

    // Load From Cache

  }

  doReset(){
     this.currentstatus = null;
     this.currentyesno = null;
     this.mytable.clear();
     this.mytable.clearState();
  }
  
  doGetTraffic(col){
  if(col==2){ return 'red'; }
  if(col==1){ return 'yellow'; }
  return 'green';
  }

  doClientSearch(event){
    this.ClientSearchEvent = event;

    this.rpc.doPost('clients/search', event).subscribe((data: any)=>{
      this.TotalClientRecords = data.result.total;
      this.Clients = data.result.records;
      if(data.result.records.length > 10){
        this.canscroll = true;
      } else {
        this.canscroll = false;
      }


    });

  }

    onRowDblClick($event, rowData) {
  setTimeout(()=>{
    this.router.navigate(['/client/' + rowData.clientid]);
  }, 50);
    }  

  doLogout(){ this.authenticationService.logout(); }

  doReports(){
  setTimeout(()=>{
    this.router.navigate(['/reports/summary']);
  }, 50);
  }

  doLeads(){
  setTimeout(()=>{
    this.router.navigate(['/newleads']);
  }, 50);
  }

  doBookings(){
  setTimeout(()=>{
    this.router.navigate(['/bookings/list']);
  }, 50);
  }


  doDiary(){
  setTimeout(()=>{
    this.router.navigate(['/diary/' + this.authenticationService.userValue.user]);
  }, 50);
  }  

  doAddClient(){

    this.clientname = "";
    this.address1 = "";
    this.address2 = "";
    this.address3 = "";
    this.address4 = "";
    this.address5 = "";
    this.postcode = "";
    this.phone = "";
    this.mobile = "";
    this.email = "";

    this.ppAddClientView = true;
  }

  doSaveClient(){

    this.ppAddClientDisabled = false;

    let data = { clientname: this.clientname, address1: this.address1, address2: this.address2, address3: this.address3,
      address4: this.address4, address5: this.address5, postcode: this.postcode, phone: this.phone, mobile: this.mobile, email: this.email };

      this.sysmsg.add({severity:'info', summary: 'New Client', detail:'Saving Client Details'})
      this.rpc.doPost('clients/saveid', data).subscribe((data: any)=>{

      setTimeout(()=>{
        this.router.navigate(['/client/' + data.result.clientid]);
      }, 50);

    }, (error)=>{
      this.sysmsg.add({severity:'error', summary: 'New Client', detail:error.message})
    });

  }



}
