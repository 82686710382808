<br>
<div style="padding: 5px;">
<p-panel>
	<p-header>Booking: {{eventname}}</p-header>

		<div class="p-grid">
			<div class="p-col-12 p-md-4 p-lg-4"><label class="lbl-right">Name:</label></div>
			<div class="p-col-12 p-md-8 p-lg-8">{{eventname}}</div>
			<div class="p-col-12 p-md-4 p-lg-4"><label class="lbl-right">address:</label></div>
			<div class="p-col-12 p-md-8 p-lg-8"><p [innerHtml]="eventaddress"></p></div>
			<div class="p-col-12 p-md-4 p-lg-4"><label class="lbl-right">phone:</label></div>
			<div class="p-col-12 p-md-8 p-lg-8">{{eventphone}}</div>
			<div class="p-col-12 p-md-4 p-lg-4"><label class="lbl-right">notes:</label></div>
			<div class="p-col-12 p-md-8 p-lg-8"><p [innerHtml]="eventdescription"></p></div>
		</div>

	<p-footer>
		<div class="p-grid">
			<div class="p-col-12 p-md-3 p-lg-1"><button pButton type="button" label='Sign Up' (click)="doSignup()" style="width: 100%"></button></div>
			<div class="p-col-12 p-md-3 p-lg-1"><button pButton type="button" label='Not Interested' (click)="doComplete(6)" style="width: 100%"></button></div>
			<div class="p-col-12 p-md-3 p-lg-1"><button pButton type="button" label='No Answer' (click)="doComplete(7)" style="width: 100%"></button></div>
			<div class="p-col-12 p-md-3 p-lg-1"><button pButton type="button" label='Re-Book' (click)="showRebook()" style="width: 100%"></button></div>
			<div class="p-col-12 p-md-3 p-lg-1"><button pButton type="button" label='Back' (click)="doBack()" style="width: 100%"></button></div>
		</div>
	</p-footer>

</p-panel>
</div>

<p-dialog [(visible)]="rebook" [modal]="true" [draggable] = "false" [style]="{width: '95vw'}">
    <p-header>
        Rebook {{eventname}}
    </p-header>

	<div class="p-grid">
		<div class="p-col-12 p-md-4 p-lg-4"><label class="lbl-right">Re-Book On:</label></div>
		<div class="p-col-12 p-md-8 p-lg-8"><p-calendar [(ngModel)]="bookingdate" dateFormat="dd/mm/yy" showIcon="true" appendTo="body"></p-calendar></div>
		<div class="p-col-12 p-md-4 p-lg-4"><label class="lbl-right">Reason:</label></div>
		<div class="p-col-12 p-md-8 p-lg-8">
			<textarea [rows]="10" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="bookingnote"></textarea>
		</div>
	</div>
  <p-footer>
		<div class="p-grid">
			<div class="p-col-12 p-md-3 p-lg-1"><button pButton type="button" label='Re-Book' (click)="doRebook()"></button></div>
			<div class="p-col-12 p-md-3 p-lg-1"><button pButton type="button" label='Cancel' (click)="rebook = false;"></button></div>
		</div>
    </p-footer>
</p-dialog>
