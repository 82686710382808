<p-menubar>
		Fitting: <p-dropdown [options]="reps" [(ngModel)]="bookrep" appendTo="body" [style]="{'width': '200px'}" (onChange)="doCalSearch()"></p-dropdown>&nbsp;
        <button pButton type="button" label="New Leads" [routerLink]="['/newleeds']"></button>&nbsp;
        <button pButton type="button" label="Bookings" [routerLink]="['/bookings/list']"></button>&nbsp;
        <button pButton type="button" label="Clients" [routerLink]="['/clientsearch']"></button>&nbsp;
        <button *ngIf="RepView" pButton type="button" label="Reports" icon="fa-plus" [routerLink]="['/reports/summary']"></button>
</p-menubar>

<p-fullCalendar #bookcal [events]="events" [options]="options"></p-fullCalendar>

<p-overlayPanel #op appendTo="body">
<div *ngIf="eventoverlay">
<p>Contact: {{eventname}}</p>
<p>Address: {{eventaddress}}</p>
<p>  Phone: {{eventphone}}</p>
<p [innerHtml]="eventdescription"></p> 
</div>
</p-overlayPanel>

<p-dialog [(visible)]="rebook" [modal]="true" [draggable] = "false" [style]="{width: '60vw'}">
    <p-header>
        Rebook {{eventname}} {{eventtitle}}
    </p-header>

	<div class="p-grid" *ngIf="rebook">
		<div class="p-col-6">
            <div class="p-grid">
                <div class="p-col-4"><label class="lbl-right">Name:</label></div>
                <div class="p-col-8">{{eventname}}</div>
                <div class="p-col-4"><label class="lbl-right">address:</label></div>
                <div class="p-col-8">{{eventaddress}}</div>
                <div class="p-col-4"><label class="lbl-right">phone:</label></div>
                <div class="p-col-8">{{eventphone}}</div>
                <div class="p-col-4"><label class="lbl-right">notes:</label></div>
                <div class="p-col-8"><p [innerHtml]="eventdescription"></p></div>
            </div>
    	</div>
		<div class="p-col-6">
            <div class="p-grid">
                <div class="p-col-4"><label class="lbl-right">Re-Book On:</label></div>
                <div class="p-col-8"><p-calendar [(ngModel)]="bookingdate" dateFormat="dd/mm/yy" appendTo="body" showIcon="true"></p-calendar></div>
                <div class="p-col-4">Fitting:</div>
                <div class="p-col-8"> <p-dropdown [options]="reps" [(ngModel)]="bookingrep" appendTo="body" [style]="{'width': '100%'}"></p-dropdown></div>
                <div class="p-col-4"><label class="lbl-right">Reason:</label></div>
                <div class="p-col-8">
                    <textarea [rows]="10" style="width: 100%;" pInputTextarea autoResize="autoResize" [(ngModel)]="bookingnote"></textarea>
                </div>
            </div>
    	</div>
    </div>
  <p-footer>
        <button pButton type="button" label='Completed' (click)="doComplete(5)"></button>
        <button pButton type="button" label='Re-Book' (click)="doRebook()"></button>
        <button pButton type="button" label='Cancel' (click)="rebook = false;"></button>
    </p-footer>
</p-dialog>
