import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  iscached(key: string){

    if(localStorage.hasOwnProperty(key)){ // loads if already set

      return true;

    }

    return false;

  }

  store(key: string, data: any){

  	// stores cache
  	localStorage.setItem(key, JSON.stringify(data));

  }

  fetch(key: string, defaultdata: any){

  	if(localStorage.hasOwnProperty(key)){ // loads if already set
  		
  		return JSON.parse(localStorage.getItem(key));

  	}

  	return defaultdata;

  }

  remove(key: string){

  	if(localStorage.hasOwnProperty(key)){ // loads if already set
  		localStorage.removeItem(key);
  	}

  }

}