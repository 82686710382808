import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';


@Component({
  selector: 'app-repview',
  templateUrl: './repview.component.html',
  styleUrls: ['./repview.component.css']
})
export class RepviewComponent implements OnInit {

  bookid: number;

  eventstartdate: string;
  eventid: number;
  eventtitle: string;
  eventname: string;
  eventdescription: string;
  eventaddress: string;
  eventphone: string;
  eventclientid: string;
  eventoverlay: boolean = false;

  rebook: boolean = false;
  ppRebookDisabled: boolean = true;
  bookingdate: Date;
  bookingrep: string;
  bookingnote: string;

  subroute: any;

  constructor(private sysmessage: MessageService, private rpc: RpcService, private router: Router, private routeparams: ActivatedRoute, private _location: Location) { }

  ngOnInit(): void {

    this.subroute = this.routeparams.params.subscribe(params => {
      
      this.bookid = +params['id'];

      // Trigers Loading
    	this.doLoad();

    });  
  
  }

  notify(typ, summ, det){
    this.sysmessage.add({severity:typ, summary: summ, detail:det});
  }

  toSQLDate(dt: Date){ 
    return dt.getFullYear() + '-' + String("00" + (dt.getMonth() + 1)).slice(-2) + '-' + String("00" + dt.getDate()).slice(-2) + ' ' + String("00" + dt.getHours()).slice(-2) + ':' + String("00" + dt.getMinutes()).slice(-2) + ':' + String("00" + dt.getSeconds()).slice(-2);
  }

  doLoad(){
    let dat = { id: this.bookid };

    this.rpc.doPost('reps/fetchbook', dat).subscribe((data: any)=>{
    
        this.eventtitle = data.result.data.title;
        this.eventstartdate = data.result.data.start;
        this.eventname = data.result.data.clientname;
        this.eventdescription = atob(data.result.data.description);
        this.eventaddress = data.result.data.address;
        this.eventclientid = data.result.data.clientid;
        this.eventphone = data.result.data.phone;
        this.bookingrep = data.result.data.rep_id;

      //this.notify('info','Client Details','Client details have been updated');

    }, (error)=>{
      this.notify('error','Booking Details',error);
      this.doBack();
    });      
    
    
  }

  doComplete(Flg){

    let data = { oldid: this.bookid, flg: Flg, note: btoa(this.bookingnote)};

    this.rpc.doPost('bookings/complete', data).subscribe((data: any)=>{

      this.notify('success','Booking Details',data.msg);
      this.doBack();

    }, (error)=>{
      this.notify('error','Booking Details',error);
    });

  }  
  
  showRebook(){
    this.bookingdate = new Date(Date.now());
    this.bookingdate.setDate( this.bookingdate.getDate() + 1);
    this.bookingnote = "";
    this.rebook = true;
  }

  doRebook(){
    if(this.bookingnote.length < 3){
      this.notify('error','Rebook','Please add reason for rebooking!');
      return;
    }

    let data = { oldid: this.bookid, bookon: this.toSQLDate(this.bookingdate), note: btoa(this.bookingnote)};
    console.log(data);

   this.rpc.doPost('bookings/rebook', data).subscribe((data: any)=>{

      this.rebook = false;
      this.notify('success','Rebooked',data.msg);
      this.doBack();

    }, (error)=>{
      this.notify('error','Rebook',error);
    });

    
  }

  doSignup(){
    setTimeout(()=>{
      this.router.navigate(['/repcontract/' + this.bookid]);
    }, 50); 
  }

  doBack(){
    setTimeout(()=>{
      this._location.back();
    }, 50);    
  }

}
