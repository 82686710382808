<p-menubar>
    <button pButton type="button" label="Clients" (click)="doClients()"></button>&nbsp;
    <button pButton type="button" label="New Leads" (click)="doLeads()"></button>&nbsp;
</p-menubar>
<div class="p-grid p-nogutter">
	<div class="p-col-12">

		<p-table #dtstat [value]="amountsumdata" [columns]="amountsumcols" [lazy]="true" (onLazyLoad)="doAmountSummary($event)" [totalRecords]="amountsumtotal" [responsive]="true" selectionMode="single">
    		<ng-template pTemplate="caption">
        		Repeat Every... Totals Summary
    		</ng-template>
    		<ng-template pTemplate="header">
        		<tr>
            		<th *ngFor="let col of amountsumcols" [pSortableColumn]="col.field">
                		{{col.header}}
            		</th>
        		</tr>
    		</ng-template>
    		<ng-template pTemplate="body" let-rowData>
        		<tr  [pSelectableRow]="rowData" (dblclick)="doDetail('re', rowData['repeat_every'])">
            		<td *ngFor="let col of amountsumcols">
                    	{{rowData[col.field]}}
            		</td>
        		</tr>
    		</ng-template>
		</p-table>	
	</div>
    <div class="p-col-12">

        <p-table #dtstat [value]="amountsumdetaildata" [columns]="amountsumdetailcols" [lazy]="true" (onLazyLoad)="doAmountSummaryDetail($event)" [totalRecords]="amountsumtotal" [responsive]="true" selectionMode="single">
            <ng-template pTemplate="caption">
                Repeat Every... Totals Summary - Amount Breakdown
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of amountsumdetailcols" [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr  [pSelectableRow]="rowData" (dblclick)="doDetail('ret', rowData['repeat_every'] + '-' + rowData['amountper'])">
                    <td *ngFor="let col of amountsumdetailcols">
                        {{rowData[col.field]}}
                    </td>
                </tr>
            </ng-template>
        </p-table>  
    </div>
	<div class="p-col-12">

		<p-table #dtstat [value]="statussumdata" [columns]="statussumcols" [lazy]="true" (onLazyLoad)="doStatusSummary($event)" [totalRecords]="statussumtotal" [responsive]="true" selectionMode="single">
    		<ng-template pTemplate="caption">
        		Status Summary
    		</ng-template>
    		<ng-template pTemplate="header">
        		<tr>
            		<th *ngFor="let col of statussumcols" [pSortableColumn]="col.field">
                		{{col.header}}
            		</th>
        		</tr>
    		</ng-template>
    		<ng-template pTemplate="body" let-rowData>
        		<tr  [pSelectableRow]="rowData" (dblclick)="doDetail('ss', rowData['status'])">
            		<td *ngFor="let col of statussumcols">
                    	{{rowData[col.field]}}
            		</td>
        		</tr>
    		</ng-template>
		</p-table>	

	</div>
	<div class="p-col-12">


		<p-table #dtpay [value]="paysumdata" [columns]="paysumcols" [lazy]="true" (onLazyLoad)="doPaySummary($event)" [totalRecords]="paysumtotal" [responsive]="true">
    		<ng-template pTemplate="caption">
        		Payment Summary
    		</ng-template>
    		<ng-template pTemplate="header">
        		<tr>
            		<th *ngFor="let col of paysumcols" [pSortableColumn]="col.field">
                		{{col.header}}
            		</th>
        		</tr>
    		</ng-template>
    		<ng-template pTemplate="body" let-rowData>
        		<tr >
            		<td *ngFor="let col of paysumcols">
                    	{{rowData[col.field]}}
            		</td>
        		</tr>
    		</ng-template>
		</p-table>		

	</div>
	<div class="p-col-12">


		<p-table #dtpaydet [value]="paysumdetailsdata" [columns]="paysumdetailscols" [lazy]="true" (onLazyLoad)="doPaySummaryDetails($event)" [totalRecords]="paysumdetailstotal" [responsive]="true">
    		<ng-template pTemplate="caption">
        		Payment Detailed Summary
    		</ng-template>
    		<ng-template pTemplate="header">
        		<tr>
            		<th *ngFor="let col of paysumdetailscols">
                		{{col.header}}
            		</th>
        		</tr>
    		</ng-template>
    		<ng-template pTemplate="body" let-rowData>
        		<tr >
            		<td *ngFor="let col of paysumdetailscols">
                    	{{rowData[col.field]}}
            		</td>
        		</tr>
    		</ng-template>
		</p-table>		
	</div>
</div>