<app-productstoolbar  addLabel="Add Order" (addEvent)="doNewOrder($event)"></app-productstoolbar>
<p-table #dt [value]="Stocks" [columns]="StockCols" [lazy]="true" (onLazyLoad)="doStockSearch($event)" [paginator]="true" 
    [rows]="50" [totalRecords]="TotalStockRecords" selectionMode="single" [(selection)]="SelectedOrder" dataKey="uuid" [responsive]="true" [rowsPerPageOptions]="[10,50,100,250,500]" [filterDelay]="1000">
    <ng-template pTemplate="header">
        <tr>
          <th style="width:40px;">&nbsp;</th>
          <th *ngFor="let col of StockCols" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th>&nbsp;</th>
        </tr>
        <tr>
			<th>&nbsp;</th>
            <th *ngFor="let col of StockCols">
                <input pInputText type="text" style="width: 100% !important;"  (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" [value]="dt.filters[col.field]?.value" >
            </th>
			<th>&nbsp;</th>
        </tr>        
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr  [pSelectableRow]="rowData" (dblclick)="doEditOrder($event, rowData)">
			<td style="width:40px;">{{i+1}}</td>
            <td *ngFor="let col of StockCols">
                    {{rowData[col.field]}}
            </td>
			<td><button pButton type="button" label="Edit" (click)="doEditOrder($event, rowData)"></button></td>
        </tr>
    </ng-template>
</p-table>
<p-dialog [(visible)]="showorderform" [modal]="true" [draggable] = "false" [style]="{width: '40vw'}">
    <p-header>
        Add Order
    </p-header>

          <div class="p-grid">
              <div class="p-col-6"><label class="lbl-right">Select Supplier:</label></div>
              <div class="p-col-6">    <p-dropdown [options]="product_suppliers"  appendTo="body" [style]="{'width': '100%'}" filter="true" [ngModel]="stock_supplier" [disabled]="isedit"
        (ngModelChange)="doProductSearch($event)"
    ></p-dropdown></div>
              <div class="p-col-6"><label class="lbl-right">Select Product:</label></div>
              <div class="p-col-6"><p-dropdown [options]="product_products"  appendTo="body" [style]="{'width': '100%'}" filter="true" [(ngModel)]="stock_product" [disabled]="isedit"
    ></p-dropdown></div>
              <div class="p-col-6"><label class="lbl-right">Order Date:</label></div>
              <div class="p-col-6"><p-calendar [(ngModel)]="order_date" dateFormat="dd/mm/yy" appendTo="body" showIcon="true" [disabled]="isedit"></p-calendar></div>
              <div class="p-col-6"><label class="lbl-right">Amount:</label></div>
              <div class="p-col-6"><p-inputNumber [disabled]="isedit" [(ngModel)]="order_amount"></p-inputNumber></div>
          </div>
    
  <p-footer>
        <button pButton type="button" label='Save' (click)="doSave()"></button>
        <button pButton type="button" label='Cancel' (click)="showorderform = false;"></button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showorderformedit" [modal]="true" [draggable] = "false" [style]="{width: '40vw'}">
    <p-header>
        Add Order
    </p-header>

          <div class="p-grid">
              <div class="p-col-6"><label class="lbl-right">Select Supplier:</label></div>
              <div class="p-col-6">    <p-dropdown [options]="product_suppliers"  appendTo="body" [style]="{'width': '100%'}" filter="true" [ngModel]="stock_supplier" [disabled]="isedit"
        (ngModelChange)="doProductSearch($event)"
    ></p-dropdown></div>
              <div class="p-col-6"><label class="lbl-right">Select Product:</label></div>
              <div class="p-col-6"><p-dropdown [options]="product_products"  appendTo="body" [style]="{'width': '100%'}" filter="true" [(ngModel)]="stock_product" [disabled]="isedit"
    ></p-dropdown></div>
              <div class="p-col-6"><label class="lbl-right">Order Date:</label></div>
              <div class="p-col-6"><p-calendar [(ngModel)]="order_date" dateFormat="dd/mm/yy" appendTo="body" showIcon="true" [disabled]="isedit"></p-calendar></div>
              <div class="p-col-6"><label class="lbl-right">Amount:</label></div>
              <div class="p-col-6"><p-inputNumber [disabled]="isedit" [(ngModel)]="order_amount"></p-inputNumber></div>
              <div class="p-col-6"><label class="lbl-right">Arrived On :</label></div>
              <div class="p-col-6"><p-calendar [(ngModel)]="arrive_date" dateFormat="dd/mm/yy" appendTo="body" showIcon="true"></p-calendar></div>
          </div>
    
  <p-footer>
        <button pButton type="button" label='Complete' (click)="doSaveEdit()"></button>
        <button pButton type="button" label='Cancel' (click)="showorderform = false;"></button>
    </p-footer>
</p-dialog>