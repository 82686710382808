import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_helpers';
import { LoginComponent } from './auth/login/login.component';
import { ClientsearchComponent, NewleadsearchComponent, RepsearchComponent, PhonesearchComponent, TransactionsComponent } from './search';
import { ClienteditComponent, NewleadseditComponent } from './edit';
import { ReportsummaryComponent, RsdetailComponent} from './reports';
import { BookinglistComponent, FittingslistComponent } from './bookings';
import { RepviewComponent, RepcontractComponent} from './reps';
import { ProductsmanagerComponent, SuppliersmanagerComponent, StocksmanagerComponent } from './products';

const routes: Routes = [
	{ path: 'products/suppliers', component: SuppliersmanagerComponent, canActivate: [AuthGuard]  },
	{ path: 'products/stock', component: StocksmanagerComponent, canActivate: [AuthGuard]  },
	{ path: 'products/products', component: ProductsmanagerComponent, canActivate: [AuthGuard]  },
	{ path: 'products', redirectTo: 'products/products' },
	{ path: 'phonesearch/:id', component: PhonesearchComponent, canActivate: [AuthGuard]  },
	{ path: 'bookings/list', component: BookinglistComponent, canActivate: [AuthGuard]  },
	{ path: 'fittings/list', component: FittingslistComponent, canActivate: [AuthGuard]  },
	{ path: 'reports/summary', component: ReportsummaryComponent, canActivate: [AuthGuard]  },
	{ path: 'reports/detail/:typ/:subtyp', component: RsdetailComponent, canActivate: [AuthGuard]  },
	{ path: 'lead/:id', component: NewleadseditComponent, canActivate: [AuthGuard] },
	{ path: 'client/:id', component: ClienteditComponent, canActivate: [AuthGuard] },
	{ path: 'newleads', component: NewleadsearchComponent, canActivate: [AuthGuard] },
	{ path: 'repcontract/:id', component: RepcontractComponent, canActivate: [AuthGuard] },
	{ path: 'repview/:id', component: RepviewComponent, canActivate: [AuthGuard] },
	{ path: 'rep', component: RepsearchComponent, canActivate: [AuthGuard] },
	{ path: 'clientsearch', component: ClientsearchComponent, canActivate: [AuthGuard] },
	{ path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuard] },
	{ path: 'bos', component: ClientsearchComponent, canActivate: [AuthGuard] },
	{ path: 'user', component: ClientsearchComponent, canActivate: [AuthGuard] },
	{ path: 'login', component: LoginComponent },
	{ path: '**', redirectTo: 'login' }  
];
 
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
