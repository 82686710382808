import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService} from 'primeng/api';
import { RpcService } from '../../service/rpc.service';
import { AuthenticationService } from '../../_services';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-phonesearch',
  templateUrl: './phonesearch.component.html',
  styleUrls: ['./phonesearch.component.css']
})
export class PhonesearchComponent implements OnInit {

	id: string;

  RepView: boolean = false;
  subroute: any;

  cols: any[];
  Clients: any;
  SelectedClient: any;
  TotalClientRecords: number;

  mbitems: MenuItem[];

  constructor(private sysmsg: MessageService, private rpc: RpcService, private router: Router, private routeparams: ActivatedRoute,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    this.cols = [
      { field: 'type', header: 'Type'},
      { field: 'ref', header: 'ID' },
      { field: 'clientname', header: 'Name' },
      { field: 'address', header: 'Address' },
      { field: 'postcode', header: 'Post Code' },
      { field: 'phone', header: 'Phone' },
    ];



    this.subroute = this.routeparams.params.subscribe(params => {
       this.id = params['id']; // (+) converts string 'id' to a number
       this.mbitems = [
          {
            label: 'Finding Number: ' + this.id,
            styleClass: 'bold-font',
          }
        ];

       // Trigers Loading
       this.doLoad();
    });



  }

  doLoad(){
    let dat = { phone: this.id }

    this.rpc.doPost('phonesearch/search', dat).subscribe((data: any)=>{

      this.TotalClientRecords = data.result.total;
      this.Clients = data.result.records;
    });

  }

  onRowDblClick($event, rowData){
    let r = "";
    if(rowData.type=='Client'){
      r = '/client/' + rowData.ref;
    } else {
      r = '/lead/' + rowData.ref;

    }
    setTimeout(()=>{
      this.router.navigate([r]);
    }, 50);
  }


  doLogout(){ this.authenticationService.logout(); }

  doReports(){
  setTimeout(()=>{
    this.router.navigate(['/reports/summary']);
  }, 50);
  }

  doLeads(){
  setTimeout(()=>{
    this.router.navigate(['/newleads']);
  }, 50);
  }

  doClients(){
  setTimeout(()=>{
    this.router.navigate(['/clientsearch']);
  }, 50);
  }

  doBookings(){
  setTimeout(()=>{
    this.router.navigate(['/bookings/list']);
  }, 50);
  }

}
