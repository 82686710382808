<p-table #dt [value]="Clients" [totalRecords]="TotalClientRecords" selectionMode="single" [(selection)]="SelectedClient" dataKey="clientid">
    <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th>Postcode</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr *ngIf="rowGroupMetadata[rowData.dt_due].index === i">
            <th colspan="2">
                <h3>{{rowData.dt_due}}</h3>
            </th>
        </tr>
        <tr  [pSelectableRow]="rowData" (dblclick)="onRowDblClick($event, rowData)">
          <td>{{rowData.clientname}}</td>
          <td>{{rowData.postcode}}</td>
        </tr>
    </ng-template>
</p-table>
